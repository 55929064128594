import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row, Collapse, Label, Input, FormGroup } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import List from './List';

import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";

// selector
import Select from "react-select";
import { experienceData, jobListCandidate, jobType } from 'common/data';
import Spinners from 'components/Common/Spinner';
import { handleSearchData } from 'components/Common/searchFile';
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getClients as onGetUsers, updateClient as onUpdateUser} from "store/users/actions";
import UpdateModal from "components/Common/UpdateModal";
import * as moment from 'moment';

const statusData = [
  {
    id: 1,
    label: "Disabled",
    value: 'disabled'
  },
  {
    id: 2,
    label: "Active",
    value: 'active',
  },
]

const CandidateList = () => {
  document.title = "Clients List | Immagoo";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();

  const selectContactsState = (state) => state.Users;
  const ContactsProperties = createSelector(
    selectContactsState,
    (Contacts) => ({
      users: Contacts.clients,
      loading: Contacts.loading
    })
  );

  const {
    users, loading
  } = useSelector(ContactsProperties);

  const [isLoading, setLoading] = useState(loading);
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickUpdate = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact._id) {
      dispatch(onUpdateUser(contact));
    }

    setDeleteModal(false);
  };
  const [listData, setListData] = useState();

  const [selectDate, setSelectDate] = useState([]);
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState([]);
  const [sort, setSort] = useState(false);

  const dateChange = (dates) => {
    setSelectDate([...dates]);
  };

  // search
  const hanldeSearchJob = (value) => {
    setSearch(value);
  }

  const statusChange = (key) => {
    status.includes(key)
        ? setStatus(status.filter(item => item !== key))
        : setStatus([...status, key]);
  };

  useEffect(() => {
    let filteredUsers = users?.length && selectDate?.length === 2 ? users?.filter((item) => {
      const date = moment(item.createdAt, 'YYYY-MM-DD');
      const startDate = moment((new Date(selectDate[0])).toDateString());
      const endDate = moment((new Date(selectDate[1])).toDateString());
      return date.isAfter(startDate) && date.isBefore(endDate);
    }) : users;

    filteredUsers = search
      ? filteredUsers.filter((item) =>
        Object.values(item).some(
          (field) =>
            typeof field === 'string' &&
            field?.toLowerCase().includes(search?.toLowerCase()),
        )
      )
      : filteredUsers;

    if(status?.length === 1) {
      filteredUsers = filteredUsers?.filter((item) => status[0] === 'active' ? !item.deactivated : item.deactivated);
    }

    filteredUsers = filteredUsers?.sort((a, b) => {
      if(sort) {
        return new Date(a.createdAt) - new Date(b.createdAt);
      } else {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
    });
    setListData(filteredUsers)
  }, [users, selectDate?.length, search, status, sort]);

  useEffect(() => {
    dispatch(onGetUsers());
  }, []);

  return (
    <React.Fragment>
      <UpdateModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="Clients List" />

          <Row>
            <Col lg={12}>
              <Card className="job-filter">
                <CardBody>
                  <form action="#">
                    <Row className="g-3">
                      <Col md={3}>
                        <div className="position-relative">
                          <Input type="text" id="searchJob" autoComplete="off" placeholder="Search your candidate" onChange={(e) => hanldeSearchJob(e.target.value)} />
                        </div>
                      </Col>
                      <Col md={2} style={{ display: 'flex', alignItems: 'center' }}>
                        {
                          (statusData || []).map((item, index) => (
                              <div className="form-check form-check-inline" key={index}>
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`inlineCheckbox${item.id}`}
                                  checked={status.includes(item.value)}
                                  onChange={() => statusChange(item.value)}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor={`inlineCheckbox${item.id}`}
                                >
                                  {item.label}
                                </Label>
                              </div>
                          ))
                        }
                      </Col>
                      <Col md={4} style={{ display: 'flex', alignItems: 'center' }}>
                        <FlatPickr
                            className="form-control"
                            selected={selectDate}
                            onChange={dateChange}
                            name="joiningDate"
                            placeholder="Select time"
                            options={{
                              dateFormat: "Y-m-d",
                              mode: "range",
                            }}
                        />
                      </Col>
                      <Col md={2} style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="form-check form-check-inline">
                          <Input
                            className="form-check-input"
                            type="checkbox" id={`inlineCheckbox-oldest`}
                            value={sort}
                            onChange={() => setSort(!sort)}
                          />
                          <Label
                            className="form-check-label"
                            htmlFor={`inlineCheckbox-oldest`}
                          >
                            Oldest first
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>

          </Row>
          {isLoading
              ? <Spinners setLoading={setLoading} />
              : <List listData={listData} onClickUpdate={onClickUpdate} />
          }
        </Container>
      </div>
    </React.Fragment>
  );
}

export default CandidateList;