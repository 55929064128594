import {
  GET_SAVED_SEARCH,
  GET_SAVED_SEARCH_SUCCESS,
  GET_SAVED_SEARCH_FAIL,
  DELETE_SAVED_SEARCH,
  DELETE_SAVED_SEARCH_SUCCESS,
  DELETE_SAVED_SEARCH_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  savedSearch: [],
};

const SavedSearch = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SAVED_SEARCH:
      return {
        ...state,
        savedSearch: action.payload,
        loading: true
      };

    case GET_SAVED_SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_SAVED_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        savedSearch: action.payload,
      };

    case DELETE_SAVED_SEARCH:
      return {
        ...state,
        loading: true,
      };

    case DELETE_SAVED_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        savedSearch: state?.savedSearch?.filter(
          ad => ad._id !== action.payload
        ),
      };

    case DELETE_SAVED_SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default SavedSearch;
