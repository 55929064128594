import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_ADS,
  ADD_NEW_AD,
  DELETE_AD, EDIT_AD
} from './actionTypes';
import {
  getAdsSuccess,
  getAdsFail,
  addAdSuccess,
  addAdFail,
  deleteAdSuccess,
  deleteAdFail, editAdSuccess, editAdFail
} from './actions';

import {
  getAds,
  deleteAd,
  addNewAd,
  editAd,
} from 'helpers/backend';

import 'react-toastify/dist/ReactToastify.css';

function* fetchAds() {
  try {
    const response = yield call(getAds);
    if(response.error) {
      yield put(getAdsFail(response));
    } else {
      yield put(getAdsSuccess(response.data));
    }
  } catch (error) {
    yield put(getAdsFail(error));
  }
}

function* onUpdateAd({ payload: add }) {
  try {
    const response = yield call(editAd, add);
    yield put(editAdSuccess(response?.data));
    toast.success("Ad Update Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(editAdFail(error));
    toast.error("Ad Update Failed", { autoClose: 2000 });
  }
}

function* onDeleteAdd({ payload: add }) {
  try {
    const response = yield call(deleteAd, add);
    yield put(deleteAdSuccess(add));
    toast.success("Ad Delete Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteAdFail(error));
    toast.error("Ad Delete Failed", { autoClose: 2000 });
  }
}

function* onAddNewAdd({ payload: add }) {
  try {
    const response = yield call(addNewAd, add);
    yield put(addAdSuccess(response));
    toast.success("Ad Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addAdFail(error));
    toast.error("Ad Added Failed", { autoClose: 2000 });
  }
}

function* adsSaga() {
  yield takeEvery(GET_ADS, fetchAds);
  yield takeEvery(EDIT_AD, onUpdateAd);
  yield takeEvery(ADD_NEW_AD, onAddNewAdd);
  yield takeEvery(DELETE_AD, onDeleteAdd);
}

export default adsSaga;
