import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row, TabContent, Table, TabPane
} from 'reactstrap';
import classnames from "classnames";
import { isEmpty, map } from "lodash";

//Import Star Ratings
import StarRatings from "react-star-ratings";

//Import Product Images
import { productImages } from "assets/images/product";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import actions
import { getPackages, deletePackage } from "store/packages/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";

const featuresKeys = {
  bundle:'Bundle',
  isCar: 'Is Car',
  categories: 'Categories',
  favoriteCount: 'Favorite Count',
  imageCount: 'Image Count',
  notificationButtonShow: 'Show Notification Button',
  savedSearchCount: 'Saved Search Count',
  blurResult: 'Blur Count'
};

const EcommerceProducts = () => {

  //meta title
  document.title = "Packages | Immagoo";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectEcommerceState = (state) => state.Packages;
  const EcommerceProductsProperties = createSelector(
    selectEcommerceState,
    (Ecommerce) => ({
      packages: Ecommerce.packages,
      loading: Ecommerce.loading
    })
  );

  const {
    packages,
    loading,
  } = useSelector(EcommerceProductsProperties);

  const [isLoading, setLoading] = useState(loading)

  const handleDelete = (id) => {
    dispatch(deletePackage(id));
  }

  //const [productList, setProductList] = useState([]);

  useEffect(() => {
    dispatch(getPackages());
  }, [dispatch]);

  // useEffect(() => {
  //   //if (!isEmpty(packages)) setProductList(packages);
  // }, [packages]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbItem="Plans"
          >
            <Button color="primary" className="btn" onClick={() => navigate('/packages-add')}>
              New
            </Button>
          </Breadcrumbs>
          <Row>
            <Col lg={12}>
              {isLoading
                ? <Spinners setLoading={setLoading} />
                : <Row>
                    {!isEmpty(packages) && (packages || []).map((product, key) => (
                        <Col md={4} key={key}>
                          <Card>
                            <CardBody>
                              <Row>
                                <Col xl="12">
                                  <div className="mt-4 mt-xl-3">
                                    <Link to="#" className="text-primary">
                                      {product.category}
                                    </Link>
                                    <h4 className="mt-1 mb-3">{product.name}</h4>
                                    <h5 className="mb-4">{product.subTitle}</h5>
                                    <h5 className="mb-4">
                                      Price :{" "}
                                      <span className="text-muted me-2">
                                       <b>${product.price} EUR</b>
                                      </span>{' '}
                                    </h5>
                                    <Row className="mt-12 col-xl-12">
                                      {product.desc &&
                                          product.desc.map((item, i) => (
                                              <div key={i}>
                                                <p className="text-muted">
                                                  <i
                                                      className={classnames(
                                                          "font-size-16 align-middle text-primary me-2 fa fa-caret-right"
                                                      )}
                                                  />
                                                  {item}
                                                </p>
                                              </div>
                                          ))}
                                    </Row>
                                  </div>
                                  <Row>
                                    <Col md="6">
                                      <Button
                                          onClick={() => navigate(`/packages-edit/${product._id}`)}
                                      >
                                        Edit
                                      </Button>
                                    </Col>
                                    <Col md="6">
                                      <Button
                                          color='danger'
                                          className=''
                                          onClick={() => handleDelete(product._id)}
                                      >
                                        Delete
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>

                              <div className="mt-5">
                                <h5 className="mb-3">Specifications :</h5>

                                <div className="table-responsive">
                                  <Table className="table mb-0 table-bordered">
                                    <tbody>
                                    {product.features &&
                                        Object.entries(product.features).map(([key, value]) => featuresKeys[key] && (
                                            <tr key={key}>
                                              <th
                                                  scope="row"
                                                  style={{ width: "400px" }}
                                                  className={"text-capitalize"}
                                              >
                                                {featuresKeys[key]}
                                              </th>
                                              <td>{typeof value === 'object' ? value.join(', ') : typeof value === 'boolean' ? value ? 'Yes' : 'No' : value}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                      </Col>
                    ))}
                  </Row>
              }
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

EcommerceProducts.propTypes = {
  ads: PropTypes.array,
  onGetProducts: PropTypes.func,
};

export default withRouter(EcommerceProducts);
