import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Progress,
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";

import { get } from "../../../helpers/api_helper";

import getChartColorsArray from '../../../components/Common/ChartsDynamicColor';
import ReactApexChart from 'react-apexcharts';

export const ByDuration = props => {
    const [periodType, setPeriodType] = useState("yearly");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const onChangeChartPeriod = pType => {
        setPeriodType(pType);
    };

    useEffect(() => {
        setLoading(true);
        get(`/reports/users-by-duration?date=${periodType}`)
            .then(({ data }) => {
                setLoading(false);
                setData(data)
            });
    }, [periodType]);
    const stackedColumnChartColors = getChartColorsArray('["--bs-primary", "--bs-warning", "--bs-success"]');

    const periodData = [
        {
            name: "Duration",
            data: data?.map(({ duration }) => duration) || []
        },
        {
            name: "Users",
            data: data?.map(({ users }) => users) || []

        }
    ];
    const options = {
        chart: {
            stacked: !0,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: !0
            }
        },
        plotOptions: {
            bar: {
                horizontal: !1,
                columnWidth: "15%"
                // endingShape: "rounded"
            }
        },
        dataLabels: {
            enabled: !1
        },
        xaxis: {
            show: true,
            categories: data?.map(({ day }) => day) || [],
            labels: {
                show: true
            }
        },
        colors: stackedColumnChartColors,
        legend: {
            position: "bottom"
        },
        fill: {
            opacity: 1
        }
    }

    return (
        <Card>
            <CardBody>
                <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Users by duration in minute</h4>
                    <div className="ms-auto">
                        <ul className="nav nav-pills">
                            <li className="nav-item">
                                <Link
                                    to="#"
                                    className={classNames(
                                        { active: periodType === 'weekly' },
                                        'nav-link'
                                    )}
                                    onClick={() => {
                                        onChangeChartPeriod('weekly');
                                    }}
                                    id="one_month"
                                >
                                    Week
                                </Link>{' '}
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="#"
                                    className={classNames(
                                        { active: periodType === 'monthly' },
                                        'nav-link'
                                    )}
                                    onClick={() => {
                                        onChangeChartPeriod('monthly');
                                    }}
                                    id="one_month"
                                >
                                    Month
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="#"
                                    className={classNames(
                                        { active: periodType === 'yearly' },
                                        'nav-link'
                                    )}
                                    onClick={() => {
                                        onChangeChartPeriod('yearly');
                                    }}
                                    id="one_month"
                                >
                                    Year
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <div className="clearfix"></div> */}
                {loading ? <Progress animated color="primary" value={100} /> : (
                    <ReactApexChart
                        options={options}
                        series={[...periodData]}
                        type="bar"
                        height="359"
                        className="apex-charts"
                    />
                )}
            </CardBody>
        </Card>
    );
};
