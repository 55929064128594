import {
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  GET_CLIENT_PROFILE,
  GET_CLIENT_PROFILE_SUCCESS,
  GET_CLIENT_PROFILE_FAIL
} from "./actionTypes"

export const getClients = () => ({
  type: GET_CLIENTS,
})

export const getClientsSuccess = ads => ({
  type: GET_CLIENTS_SUCCESS,
  payload: ads,
})

export const getClientsFail = error => ({
  type: GET_CLIENTS_FAIL,
  payload: error,
})

export const updateClient = ad => ({
  type: UPDATE_CLIENT,
  payload: ad,
})

export const updateClientSuccess = ad => ({
  type: UPDATE_CLIENT_SUCCESS,
  payload: ad,
})

export const updateClientFail = error => ({
  type: UPDATE_CLIENT_FAIL,
  payload: error,
})

export const getClientProfile = (data) => ({
  type: GET_CLIENT_PROFILE,
  payload: data
})

export const getClientProfileSuccess = userProfile => ({
  type: GET_CLIENT_PROFILE_SUCCESS,
  payload: userProfile,
})

export const getClientProfileFail = error => ({
  type: GET_CLIENT_PROFILE_FAIL,
  payload: error,
})
