import {
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  GET_CLIENT_PROFILE_SUCCESS,
  GET_CLIENT_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  clients: [],
  userProfile: {},
  error: {},
  loading: true
};

const Clients = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.payload,
        loading: true
      };

    case GET_CLIENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: action.payload,
      };

    case UPDATE_CLIENT:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: action.payload,
      };

    case UPDATE_CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_CLIENT_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      }

    case GET_CLIENT_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state;
  }
};

export default Clients;
