import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Progress
} from 'reactstrap';

import { get } from "../../../helpers/api_helper";

export const SummaryUsers = props => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        get(`/reports/summary-users`)
            .then(({ data }) => {
                setLoading(false);
                setData(data)
            });
    }, []);

    return loading ? <Progress animated color="primary" value={100} /> : (
        <>
            <Col md="3" key={"_col_"}>
                <Card className="mini-stats-wid">
                    <CardBody>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                    Active subscriptions
                                </p>
                                <h4 className="mb-0">{data.activeSubscriptions}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                  className={
                                      "bx bxs-user-plus font-size-24"
                                  }
                              ></i>
                            </span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col md="3" key={"_col_"}>
                <Card className="mini-stats-wid">
                    <CardBody>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                    Inactive subscriptions
                                </p>
                                <h4 className="mb-0">{data.inactiveSubscriptions}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                  className={
                                      "bx bxs-user-minus font-size-24"
                                  }
                              ></i>
                            </span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col md="3" key={"_col_"}>
                <Card className="mini-stats-wid">
                    <CardBody>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                    Active users
                                </p>
                                <h4 className="mb-0">{data.activeUsers}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                  className={
                                      "bx bxs-user-check font-size-24"
                                  }
                              ></i>
                            </span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col md="3" key={"_col_"}>
                <Card className="mini-stats-wid">
                    <CardBody>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                    Inactive users
                                </p>
                                <h4 className="mb-0">{data.inactiveUsers}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                  className={
                                      "bx bxs-user-x font-size-24"
                                  }
                              ></i>
                            </span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};
