import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { map } from "lodash";
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    Table, UncontrolledTooltip
} from 'reactstrap';

// TableContainer

import { Pdate, Ddate, Name, Idno, Budget } from "./CryptoCol";

import TableContainer from "../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import mini card widgets
import MiniCards from "./mini-card";

//Import Images
import profile1 from "assets/images/profile-img.png";

// import charts
import ApexRevenue from "./ApexRevenue";
import { getClientProfile } from "store/actions";
import { BillingName, Date, OrderId, PaymentStatus, Total } from '../../SavedSearch/EcommerceOrderCol';
import { defaultStyles, JsonView } from 'react-json-view-lite';
import { get } from '../../../helpers/api_helper';

const ContactsProfile = props => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    //meta title
    document.title = "Profile | Skote - React Admin & Dashboard Template";

    const { userProfile, onGetUserProfile } = props;
    // eslint-disable-next-line no-unused-vars

    useEffect(() => {
        onGetUserProfile(id);
        get(`/reports/user-reports/${id}`)
            .then(({ data }) => {
                setLoading(false);
                setData(data)
            });
    }, [onGetUserProfile, id]);

    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: '_id',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                filterable: true,
                Cell: (cellProps) => {
                    return <OrderId {...cellProps} />;
                }
            },
            {
                Header: 'Country',
                accessor: 'country',
                filterable: true,
                Cell: (cellProps) => {
                    return <BillingName {...cellProps} />;
                }
            },
            {
                Header: 'Date',
                accessor: 'createdAt',
                filterable: true,
                Cell: (cellProps) => {
                    return <Date {...cellProps} />;
                }
            },
            {
                Header: 'Is Car',
                accessor: 'isCar',
                filterable: true,
                Cell: (cellProps) => {
                    return cellProps.value ? 'Yes' : 'No';
                }
            },
            {
                Header: 'Is Read',
                accessor: 'read',
                Cell: (cellProps) => {
                    return <PaymentStatus {...cellProps} />;
                }
            },
            {
                Header: 'Filter',
                accessor: 'filter',
                Cell: (cellProps) => {
                    return <JsonView data={cellProps.value} shouldExpandNode={() => false} style={defaultStyles} />
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    // const orderData = cellProps.row.original;
                                    // onClickDelete(orderData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                }
            },
        ],
        []
    );

    const activeSubscription = data?.subscriptions?.find(sub => sub.status === 'active');

    let totalSpent = 0;

    data?.stripe?.forEach(({items}) => {
        items?.data.forEach(item => {
            totalSpent += item.price.unit_amount/100;
        })
    });



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="" breadcrumbItem="Profile" />

                    <Row>
                        <Col xl="4">
                            <Card className="overflow-hidden">
                                <div className="bg-primary-subtle">
                                    <Row>
                                        <Col xs="7">
                                            <div className="text-primary p-3"></div>
                                        </Col>
                                        <Col xs="5" className="align-self-end">
                                            <img src={profile1} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <Row>
                                        <Col sm="4">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                {!userProfile.picture ? (
                                                    <div className="avatar-sm d-inline-block">
                                                        <span className="avatar-title rounded-circle">
                                                          {userProfile?.name?.charAt(0) || userProfile?.userProfile?.charAt(0).toUpperCase()}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <img
                                                        src={userProfile.picture}
                                                        alt=""
                                                        className="img-thumbnail rounded-circle"
                                                    />
                                                )}
                                            </div>
                                            <h5 className="font-size-15 text-truncate">
                                                {userProfile.name}
                                            </h5>
                                        </Col>

                                        <Col sm={8}>
                                            <div className="pt-4">
                                            <Row>
                                                    <Col xs="12">
                                                        <h5 className="font-size-15">
                                                            {userProfile.email}
                                                        </h5>
                                                        <p className="text-muted mb-0">Email</p>
                                                        <p className="text-muted mb-0">
                                                            Next Payment Date: {activeSubscription?.nextPaymentDate?.split('T')[0]}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Personal Information</CardTitle>
                                    <div className="table-responsive">
                                        <Table className="table-nowrap mb-0">
                                            <tbody>
                                            <tr>
                                                <th scope="row">Full Name :</th>
                                                <td>{userProfile.name}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Mobile :</th>
                                                <td>{userProfile.phone || '---'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Location :</th>
                                                <td>
                                                    {userProfile.country}
                                                    {userProfile.city}
                                                    {userProfile.address || '---'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Postal Code :</th>
                                                <td>{userProfile.zipCode || '---'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Current country :</th>
                                                <td>{userProfile.currentCountry || '---'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Birthday :</th>
                                                <td>{userProfile.birthday || '---'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Gender :</th>
                                                <td>{userProfile.gender || '---'}</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl="8">
                            <Row>
                                <MiniCards
                                    title='Saved Search'
                                    text={userProfile?.filters?.length || 0}
                                    iconClass={'bx-hourglass'}
                                />
                                <MiniCards
                                    title='Favorites'
                                    text={userProfile?.favorites?.length || 0}
                                    iconClass={'bx-check-circle'}
                                />
                                <MiniCards
                                    title='Active Subscription'
                                    text={activeSubscription ? 'Active' : 'Inactive'}
                                    iconClass={'bx-check-circle'}
                                />
                                <MiniCards
                                    title="Total Spent"
                                    text={`Eur ${totalSpent}`}
                                    iconClass="bx-package"
                                />
                            </Row>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Saved Searches</CardTitle>
                                    <TableContainer
                                        columns={columns}
                                        data={userProfile.filters || []}
                                        isGlobalFilter={false}
                                        customPageSize={7}
                                        customPageSizeOptions={true}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

ContactsProfile.propTypes = {
    userProfile: PropTypes.any,
    onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ Users }) => ({
    userProfile: Users.userProfile,
});

const mapDispatchToProps = dispatch => ({
    onGetUserProfile: (id) => dispatch(getClientProfile(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ContactsProfile));
