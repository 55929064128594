import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { Badge } from 'reactstrap';

const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};
const toLowerCase1 = str => {
    return (
      str === "" || str === undefined ? "" : str.toLowerCase()
    );
  };

const CheckBox = (cell) => {
    return cell.value ? cell.value : '';
};

const OrderId = (cell) => {
    return (
        <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
    );
};

const BillingName = (cell) => {
    return cell.value ? cell.value : '';
};

const Date = (cell) => {
    return cell.value ? cell.value : '';
};

const Total = (cell) => {
    return cell.value ? cell.value + ' ' + cell.row.original.currency : '';
};

const PaymentStatus = (cell) => {
    return (
        <Badge
          className={"font-size-11 badge-soft-" + 
          (cell.value === "succeeded" ? "success" : "danger" && cell.value === "refund" ? "warning" : "danger")}
        >
          {cell.value}
        </Badge>
    )
};
const PaymentMethod = (cell) => {
    const { original } = cell.row;
    const { payment_method_details } = original;
    return (
        <span>
        <i
        className={
          (cell.value === "paypal" ? "fab fa-cc-paypal me-1" : "" ||
          cell.value === "card"
                  ? payment_method_details.card.brand === 'mastercard'
                      ? "fab fa-cc-mastercard me-1"
                      : "fab fa-cc-visa me-1"
                  : ""
          )}
          />{" "}
            {cell.value}
        </span>
    )
};
export {
    CheckBox,
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod
};