/* PRODUCTS */
export const GET_CAR_ADS = "GET_CAR_ADS"
export const GET_CAR_ADS_SUCCESS = "GET_CAR_ADS_SUCCESS"
export const GET_CAR_ADS_FAIL = "GET_CAR_ADS_FAIL"

export const ADD_NEW_CAR_AD = "ADD_NEW_CAR_AD"
export const ADD_CAR_AD_SUCCESS = "ADD_CAR_AD_SUCCESS"
export const ADD_CAR_AD_FAIL = "ADD_CAR_AD_FAIL"

export const EDIT_CAR_AD = "EDIT_CAR_AD"
export const EDIT_CAR_AD_SUCCESS = "EDIT_CAR_AD_SUCCESS"
export const EDIT_CAR_AD_FAIL = "EDIT_CAR_AD_FAIL"

export const DELETE_CAR_AD = "DELETE_CAR_AD"
export const DELETE_CAR_AD_SUCCESS = "DELETE_CAR_AD_SUCCESS"
export const DELETE_CAR_AD_FAIL = "DELETE_CAR_AD_FAIL"
