import {
  GET_PACKAGES,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAIL,
  ADD_PACKAGE_SUCCESS,
  ADD_PACKAGE_FAIL,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_FAIL,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  packages: [],
};

const Packages = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PACKAGES:
      return {
        ...state,
        packages: action.payload,
        loading: true
      };

    case GET_PACKAGES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_PACKAGES_SUCCESS:
      return {
        ...state,
        packages: action.payload,
      };

    case ADD_PACKAGE_SUCCESS:
      return {
        ...state,
        packages: action.payload,
      };

    case ADD_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        packages: action.payload,
      };

    case UPDATE_PACKAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_PACKAGE_SUCCESS:
      return {
        ...state,
        packages: state.packages.filter(
          ad => ad._id !== action.payload
        ),
      };

    case DELETE_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Packages;
