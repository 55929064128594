import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_PACKAGES,
  ADD_NEW_PACKAGE,
  UPDATE_PACKAGE,
  DELETE_PACKAGE,
} from "./actionTypes"
import {
  getPackagesSuccess,
  getPackagesFail,
  addPackageSuccess,
  addPackageFail,
  updatePackageSuccess,
  updatePackageFail,
  deletePackageSuccess,
  deletePackageFail,
} from "./actions";

import {
  getPackages,
  deletePackage,
  updatePackage,
  addNewPackage,
} from 'helpers/backend';

import 'react-toastify/dist/ReactToastify.css';

function* fetchPackages() {
  try {
    const response = yield call(getPackages);
    if(response.error) {
      yield put(getPackagesFail(response));
    } else {
      yield put(getPackagesSuccess(response.data));
    }
  } catch (error) {
    yield put(getPackagesFail(error));
  }
}

function* onDeletePackage({ payload: add }) {
  try {
    const response = yield call(deletePackage, add);
    yield put(deletePackageSuccess(add));
    toast.success("Package Delete Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deletePackageFail(error));
    toast.error("Package Delete Failed", { autoClose: 2000 });
  }
}

function* onUpdatePackage({ payload: add }) {
  try {
    const response = yield call(updatePackage, add);
    yield put(updatePackageSuccess(response?.data));
    toast.success("Package Update Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(updatePackageFail(error));
    toast.error("Package Update Failed", { autoClose: 2000 });
  }
}

function* onAddNewPackage({ payload: add }) {
  try {
    const response = yield call(addNewPackage, add);
    yield put(addPackageSuccess(response));
    toast.success("Package Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addPackageFail(error));
    toast.error("Package Added Failed", { autoClose: 2000 });
  }
}

function* packagesSaga() {
  yield takeEvery(GET_PACKAGES, fetchPackages);
  yield takeEvery(ADD_NEW_PACKAGE, onAddNewPackage);
  yield takeEvery(DELETE_PACKAGE, onDeletePackage);
  yield takeEvery(UPDATE_PACKAGE, onUpdatePackage);
}

export default packagesSaga;
