import {
  GET_PACKAGES,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAIL,
  ADD_NEW_PACKAGE,
  ADD_PACKAGE_SUCCESS,
  ADD_PACKAGE_FAIL,
  DELETE_PACKAGE,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAIL,
  UPDATE_PACKAGE,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_FAIL,
} from "./actionTypes"

export const getPackages = () => ({
  type: GET_PACKAGES,
})

export const getPackagesSuccess = ads => ({
  type: GET_PACKAGES_SUCCESS,
  payload: ads,
})

export const getPackagesFail = error => ({
  type: GET_PACKAGES_FAIL,
  payload: error,
})

export const addNewPackage = ad => ({
  type: ADD_NEW_PACKAGE,
  payload: ad,
})

export const addPackageSuccess = ad => ({
  type: ADD_PACKAGE_SUCCESS,
  payload: ad,
})

export const addPackageFail = error => ({
  type: ADD_PACKAGE_FAIL,
  payload: error,
})

export const updatePackage = ad => ({
  type: UPDATE_PACKAGE,
  payload: ad,
})

export const updatePackageSuccess = ad => ({
  type: UPDATE_PACKAGE_SUCCESS,
  payload: ad,
})

export const updatePackageFail = error => ({
  type: UPDATE_PACKAGE_FAIL,
  payload: error,
})

export const deletePackage = ad => ({
  type: DELETE_PACKAGE,
  payload: ad,
})

export const deletePackageSuccess = ad => ({
  type: DELETE_PACKAGE_SUCCESS,
  payload: ad,
})

export const deletePackageFail = error => ({
  type: DELETE_PACKAGE_FAIL,
  payload: error,
})
