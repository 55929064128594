import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import {
  FormFeedback,
  CardTitle,
  Container,
  CardBody,
  Button,
  Input,
  Label,
  Card,
  Form,
  Col,
  Row,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import { addNewCarAd } from "../../store/car-ads/actions";

const countries = [
  { value: 'germany', label: 'Germany' },
  { value: 'usa', label: 'USA' },
  { value: 'uk', label: 'Great britain' },
  { value: 'russia', label: 'Russia' },
]

const AddCarAd = () => {

  //meta title
  document.title = "Add Car Ad | Immagoo";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      country: '',
      header: '',
      description: '',
      location: '',
      price: '',
      currency: '',
      year: '',
      body: '',
      miles: '',
      engine: '',
      horsePower: '',
      transmission: '',
      fuelType: '',
      photo: '',
      photoSecond: '',
      photoFirth: '',
      photoFourth: '',
      website: '',
      url: '',
    },
    validationSchema: Yup.object({
      country: Yup.string().required("Please Select Country"),
      header: Yup.string().required("Please Enter Header"),
      description: Yup.string().required("Please Enter Description"),
      location: Yup.string().required("Please Enter Location"),
      price: Yup.number().required("Please Enter Price"),
      currency: Yup.string().required("Please Enter Currency"),
      year: Yup.number().required("Please Enter Year"),
      body: Yup.string().required("Please Enter Body Type"),
      miles: Yup.number().required("Please Enter Miles"),
      engine: Yup.string().required("Please Enter Engine Power"),
      horsePower: Yup.string().required("Please Enter Horse Power"),
      transmission: Yup.string().required("Please Enter Transmission"),
      fuelType: Yup.string().required("Please Enter Fuel Type"),
      photo: Yup.string().url().required("Please Enter Photo"),
      photoSecond: Yup.string().url(),
      photoFirth: Yup.string().url(),
      photoFourth: Yup.string().url(),
      website: Yup.string().required("Please Enter Website"),
      url: Yup.string().url().required("Please Enter Website"),
    }),
    onSubmit: (values) => {
      const {
        country,
        header,
        description,
        location,
        price,
        currency,
        year,
        body,
        miles,
        engine,
        horsePower,
        transmission,
        fuelType,
        photo,
        photoSecond,
        photoFirth,
        photoFourth,
        website,
        url,
      } = values;
      dispatch(addNewCarAd(
        {
          country,
          header,
          description,
          location,
          year,
          body,
          miles,
          engine,
          horsePower,
          transmission,
          fuelType,
          price: {
            main: price,
            currency,
          },
          link: url,
          website: [
            {
              value: website,
              link: url
            }
          ],
          photos: [
            photo,
            photoSecond,
            photoFirth,
            photoFourth
          ]
        }
      ));
      navigate('/car-ads');
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Car Ads" breadcrumbItem="Add CarPage Ad" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Car Ad Information</CardTitle>
                  <p className="card-title-desc mb-4">
                    Fill all information below
                  </p>

                  <Form onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="control-label">Country</Label>
                          <Select
                            classNamePrefix="select2-selection"
                            placeholder="Choose..."
                            title="Country"
                            name='country'
                            options={countries}
                            value={countries ? countries.find(option => option.value === validation.touched.country) : ''}
                            onChange={(option) => validation.setFieldValue('country', option.value)}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.country && validation.errors.country ? true : false
                            }
                          />
                          {validation.touched.country && validation.errors.country ? (
                            <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="location">Location</Label>
                          <Input
                            id="location"
                            name="location"
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.location || ""}
                            invalid={
                              validation.touched.location && validation.errors.location ? true : false
                            }
                          />
                          {validation.touched.location && validation.errors.location ? (
                            <FormFeedback type="invalid">{validation.errors.location}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="header">
                            Header
                          </Label>
                          <Input
                            id="header"
                            name="header"
                            type="text"
                            className="form-control"
                            placeholder="header"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.header || ""}
                            invalid={
                              validation.touched.header && validation.errors.header ? true : false
                            }
                          />
                          {validation.touched.header && validation.errors.header ? (
                            <FormFeedback type="invalid">{validation.errors.header}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="description">Description</Label>
                          <Input
                            id="description"
                            name="description"
                            type="text"
                            className="form-control"
                            placeholder="Description"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            invalid={
                              validation.touched.description && validation.errors.description ? true : false
                            }
                          />
                          {validation.touched.description && validation.errors.description ? (
                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="miles">Miles</Label>
                          <Input
                            id="miles"
                            name="miles"
                            type="text"
                            className="form-control"
                            placeholder="Miles"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.miles || ""}
                            invalid={
                              validation.touched.miles && validation.errors.miles ? true : false
                            }
                          />
                          {validation.touched.miles && validation.errors.miles ? (
                            <FormFeedback type="invalid">{validation.errors.miles}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="engine">Engine</Label>
                          <Input
                            id="engine"
                            name="engine"
                            type="text"
                            className="form-control"
                            placeholder="Engine"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.engine || ""}
                            invalid={
                              validation.touched.engine && validation.errors.engine ? true : false
                            }
                          />
                          {validation.touched.engine && validation.errors.engine ? (
                            <FormFeedback type="invalid">{validation.errors.engine}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="horsePower">Horse Power</Label>
                          <Input
                            id="horsePower"
                            name="horsePower"
                            type="text"
                            className="form-control"
                            placeholder="Horse Power"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.horsePower || ""}
                            invalid={
                              validation.touched.horsePower && validation.errors.horsePower ? true : false
                            }
                          />
                          {validation.touched.horsePower && validation.errors.horsePower ? (
                            <FormFeedback type="invalid">{validation.errors.horsePower}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="price">Price</Label>
                          <Input
                            id="price"
                            name="price"
                            type="number"
                            className="form-control"
                            placeholder="Price"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.price || ""}
                            invalid={
                              validation.touched.price && validation.errors.price ? true : false
                            }
                          />
                          {validation.touched.price && validation.errors.price ? (
                            <FormFeedback type="invalid">{validation.errors.price}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="currency">
                            Currency
                          </Label>
                          <Input
                            id="currency"
                            name="currency"
                            type="text"
                            className="form-control"
                            placeholder="Currency"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.currency || ""}
                            invalid={
                              validation.touched.currency && validation.errors.currency ? true : false
                            }
                          />
                          {validation.touched.currency && validation.errors.currency ? (
                            <FormFeedback type="invalid">{validation.errors.currency}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="year">Year</Label>
                          <Input
                            id="year"
                            name="year"
                            type="number"
                            className="form-control"
                            placeholder="year"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.year || ""}
                            invalid={
                              validation.touched.year && validation.errors.year ? true : false
                            }
                          />
                          {validation.touched.year && validation.errors.year ? (
                            <FormFeedback type="invalid">{validation.errors.year}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="body">Body</Label>
                          <Input
                            id="body"
                            name="body"
                            type="text"
                            className="form-control"
                            placeholder="body"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.body || ""}
                            invalid={
                              validation.touched.body && validation.errors.body ? true : false
                            }
                          />
                          {validation.touched.body && validation.errors.body ? (
                            <FormFeedback type="invalid">{validation.errors.body}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="transmission">Transmission</Label>
                          <Input
                            id="transmission"
                            name="transmission"
                            type="text"
                            className="form-control"
                            placeholder="Transmission"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.transmission || ""}
                            invalid={
                              validation.touched.transmission && validation.errors.transmission ? true : false
                            }
                          />
                          {validation.touched.transmission && validation.errors.transmission ? (
                            <FormFeedback type="invalid">{validation.errors.transmission}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="fuelType">Fuel Type</Label>
                          <Input
                            id="fuelType"
                            name="fuelType"
                            type="text"
                            className="form-control"
                            placeholder="Fuel Type"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.fuelType || ""}
                            invalid={
                              validation.touched.fuelType && validation.errors.fuelType ? true : false
                            }
                          />
                          {validation.touched.fuelType && validation.errors.fuelType ? (
                            <FormFeedback type="invalid">{validation.errors.fuelType}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm="12">
                        <div className="mb-3">
                          <Label htmlFor="website">
                            Website
                          </Label>
                          <Input
                            id="website"
                            name="website"
                            type="text"
                            className="form-control"
                            placeholder="Website"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.website || ""}
                            invalid={
                              validation.touched.website && validation.errors.website ? true : false
                            }
                          />
                          {validation.touched.website && validation.errors.website ? (
                            <FormFeedback type="invalid">{validation.errors.website}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="url">
                            URL
                          </Label>
                          <Input
                            id="url"
                            name="url"
                            type="text"
                            className="form-control"
                            placeholder="Url"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.url || ""}
                            invalid={
                              validation.touched.url && validation.errors.url ? true : false
                            }
                          />
                          {validation.touched.url && validation.errors.url ? (
                            <FormFeedback type="invalid">{validation.errors.url}</FormFeedback>
                          ) : null}
                        </div>
                        {['photo', 'photoSecond', 'photoFirth', 'photoFourth'].map((photo, index) => (
                            <div className="mb-3" key={photo}>
                              <Label htmlFor="photo">
                                Photo {index+1}
                              </Label>
                              <Input
                                  id={photo}
                                  name={photo}
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values[photo] || ""}
                                  invalid={
                                    validation.touched[photo] && validation.errors[photo] ? true : false
                                  }
                              />
                              {validation.touched[photo] && validation.errors[photo] ? (
                                  <FormFeedback type="invalid">{validation.errors[photo]}</FormFeedback>
                              ) : null}
                            </div>
                        ))}
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Save Changes
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddCarAd
