import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import {
  FormFeedback,
  CardTitle,
  Container,
  CardBody,
  Button,
  Input,
  Label,
  Card,
  Form,
  Col,
  Row,
} from "reactstrap";
import { updatePackage } from "store/packages/actions";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import { createSelector } from 'reselect';

import carCategories from './carCategories';
import estateCategories from './estateCategories';

const categories = [
  { value: 'estate', label: 'Estate'},
  { value: 'auto', label: 'Auto' },
  { value: 'bundle', label: 'Bundle' },
];

const icons = [
  { value: 'AutoStartPlanIcon', label: 'Start Auto Plan Icon'},
  { value: 'AutoProfessionalPlanicon', label: 'Professional Auto Plan Icon'},
  { value: 'StartPlanIcon', label: 'Start Estate Plan Icon'},
  { value: 'ProfessionalPlanicon', label: 'Professional Estate Plan Icon'},
  { value: 'AutoImmobilenIcon', label: 'Bundle Plan Icon' },
]

const Add = () => {
  const { id } = useParams();

  //meta title
  document.title = "Add Add | Immagoo";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectEcommerceState = (state) => state.Packages;
  const EcommerceProductsProperties = createSelector(
      selectEcommerceState,
      (Ecommerce) => ({
        packages: Ecommerce.packages,
        loading: Ecommerce.loading
      })
  );

  const {
    packages,
    loading,
  } = useSelector(EcommerceProductsProperties);

  const pack = packages?.find(item => item._id === id);

  const { features = {} } = pack || {};

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: pack?.name,
      category: pack?.category,
      market: pack?.market,
      subTitle: pack?.subTitle,
      desc: pack?.desc,
      price: pack?.price,
      icon: pack?.icon,
      stripeId: pack?.stripeId,
      iosId: pack?.iosId,
      suggested: pack?.suggested,
      features: {
        savedSearchCount: features?.savedSearchCount,
        favoriteCount: features?.favoriteCount,
        imageCount: features?.imageCount,
        bundle: features?.bundle,
        isCar: features?.isCar,
        blurResult: features?.blurResult,
        notificationButtonShow: features?.notificationButtonShow,
        categories: features?.categories,
      },
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Select Country"),
      category: Yup.string().required("Please Choose Category"),
      market: Yup.mixed().required("Please Choose Market"),
      subTitle: Yup.string().required("Please Enter Locality"),
      desc: Yup.array().of(Yup.string()).required("Please Enter Postal Code"),
      price: Yup.number().required("Please Enter Price"),
      icon: Yup.string().required("Please Enter Currency"),
      stripeId: Yup.string().required("Please Enter Stripe ID"),
      iosId: Yup.string().required("Please Enter Ios ID"),
      suggested: Yup.bool().required("Please Enter Beds"),
      features: Yup.object().shape({
          savedSearchCount: Yup.number().required("Please Enter Saved Search Count"),
          favoriteCount: Yup.number().required("Please Enter Favorite Count"),
          imageCount: Yup.number().required("Please Enter Image Count"),
          //bundle: Yup.bool().required("Please Enter Bundle"),
          //isCar: Yup.bool().required("Please Enter Car"),
          //blurResult: Yup.bool().required("Please Enter Blur Result"),
          //notificationButtonShow: Yup.bool().required("Please Enter Notification Button Show"),
          //categories: Yup.array().of(Yup.string()).required("Please Enter Categories"),
      }),
    }),
    onSubmit: (values) => {
      console.log(id);
      const {
        name,
        category,
        market,
        subTitle,
        desc,
        price,
        icon,
        stripeId,
        iosId,
        suggested,
        features: {
          savedSearchCount,
          favoriteCount,
          imageCount,
          blurResult,
          notificationButtonShow,
          categories,
        }
      } = values;
      dispatch(updatePackage({
        _id: id,
        name,
        category,
        market,
        subTitle,
        desc,
        price,
        icon,
        stripeId,
        iosId,
        suggested,
        features: {
          planId: id,
          savedSearchCount,
          favoriteCount,
          imageCount,
          bundle: isBundle,
          isCar: isCar,
          blurResult,
          notificationButtonShow,
          categories,
        }
      }));

      navigate('/packages');
    }
  });
  console.log(validation.errors);
  const isCar = validation.values.market === 'auto';
  const isBundle = validation.values.market === 'bundle';

  const handleRemoveRow = (index) => {
    const values = [...validation.values.desc];
    values.splice(index, 1);
    validation.setFieldValue('desc', values);
  }

  const handleAddRowNested = () => {
    const values = [...validation.values.desc];
    values.push('');
    validation.setFieldValue('desc', values);
  }

  const handleChangeRow = (index, value) => {
    const values = [...validation.values.desc];
    values[index] = value;
    validation.setFieldValue('desc', values);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Plans" breadcrumbItem="Edit Plan" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Plan Information</CardTitle>
                  <p className="card-title-desc mb-4">
                    Fill all information below
                  </p>

                  <Form onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="address">Name</Label>
                          <Input
                              id="name"
                              name="name"
                              type="text"
                              className="form-control"
                              placeholder="Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ''}
                              invalid={
                                validation.touched.name && validation.errors.name ? true : false
                              }
                          />
                          {validation.touched.name && validation.errors.name ? (
                              <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="address">Subtitle</Label>
                          <Input
                              id="subTitle"
                              name="subTitle"
                              type="text"
                              className="form-control"
                              placeholder="Sub Title"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.subTitle || ''}
                              invalid={
                                validation.touched.subTitle && validation.errors.subTitle ? true : false
                              }
                          />
                          {validation.touched.subTitle && validation.errors.subTitle ? (
                              <FormFeedback type="invalid">{validation.errors.subTitle}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="address">Stripe Id</Label>
                          <Input
                              id="stripeId"
                              name="stripeId"
                              type="text"
                              className="form-control"
                              placeholder="Stripe ID"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.stripeId || ''}
                              invalid={
                                validation.touched.stripeId && validation.errors.stripeId ? true : false
                              }
                          />
                          {validation.touched.stripeId && validation.errors.stripeId ? (
                              <FormFeedback type="invalid">{validation.errors.stripeId}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="address">Stripe Id</Label>
                          <Input
                              id="iosId"
                              name="iosId"
                              type="text"
                              className="form-control"
                              placeholder="Ios ID"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.iosId || ''}
                              invalid={
                                validation.touched.iosId && validation.errors.iosId ? true : false
                              }
                          />
                          {validation.touched.iosId && validation.errors.iosId ? (
                              <FormFeedback type="invalid">{validation.errors.iosId}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="address">Category</Label>
                          <Input
                              id="category"
                              name="category"
                              type="text"
                              className="form-control"
                              placeholder="Category"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.category || ''}
                              invalid={
                                validation.touched.category && validation.errors.category ? true : false
                              }
                          />
                          {validation.touched.category && validation.errors.category ? (
                              <FormFeedback type="invalid">{validation.errors.category}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="address">Price</Label>
                          <Input
                              id="price"
                              name="price"
                              type="number"
                              className="form-control"
                              placeholder="Price"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.price || ''}
                              invalid={
                                validation.touched.price && validation.errors.price ? true : false
                              }
                          />
                          {validation.touched.price && validation.errors.price ? (
                              <FormFeedback type="invalid">{validation.errors.price}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Market</Label>
                          <Select
                              classNamePrefix="select2-selection"
                              placeholder="Choose..."
                              title="Market"
                              name="market"
                              options={categories}
                              value={categories ? categories.find(option => validation.values?.market === option.value) : ''}
                              onChange={(option) => validation.setFieldValue('market', option.value)}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.market && validation.errors.market ? true : false
                              }
                          />
                          {validation.touched.market && validation.errors.market ? (
                              <FormFeedback type="invalid">{validation.errors.market}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Icon</Label>
                          <Select
                              classNamePrefix="select2-selection"
                              placeholder="Choose..."
                              title="Icon"
                              name="icon"
                              options={icons}
                              value={icons ? icons.find(option => option.value === validation.values.icon) : ''}
                              onChange={(option) => validation.setFieldValue('icon', option.value)}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.icon && validation.errors.icon ? true : false
                              }
                          />
                          {validation.touched.icon && validation.errors.icon ? (
                              <FormFeedback type="invalid">{validation.errors.icon}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="form-check form-switch form-switch-lg mb-3">
                          <Input
                              id="suggested"
                              name="suggested"
                              type="checkbox"
                              className="form-control form-check-input"
                              placeholder="Suggested"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.suggested || false}
                              invalid={
                                validation.touched.suggested && validation.errors.suggested ? true : false
                              }
                          />
                          <label
                              className="form-check-label"
                              htmlFor="customSwitchsizelg"
                          >
                            Suggested
                          </label>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="price">Saved Search Count</Label>
                          <Input
                              id="savedSearchCount"
                              name="features.savedSearchCount"
                              type="number"
                              className="form-control"
                              placeholder="Saved Search Count"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values?.features?.savedSearchCount || ''}
                              invalid={
                                validation.touched?.features?.savedSearchCount && validation.errors?.features?.savedSearchCount ? true : false
                              }
                          />
                          {validation.touched?.features?.savedSearchCount && validation.errors?.features?.savedSearchCount ? (
                              <FormFeedback
                                  type="invalid">{validation.errors?.features?.savedSearchCount}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="price">Favorite Count</Label>
                          <Input
                              id="favoriteCount"
                              name="features.favoriteCount"
                              type="number"
                              className="form-control"
                              placeholder="Favorite Count"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values?.features?.favoriteCount || ''}
                              invalid={
                                validation.touched?.features?.favoriteCount && validation.errors?.features?.favoriteCount ? true : false
                              }
                          />
                          {validation.touched?.features?.favoriteCount && validation.errors?.features?.favoriteCount ? (
                              <FormFeedback
                                  type="invalid">{validation.errors?.features?.favoriteCount}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="price">Image Count</Label>
                          <Input
                              id="imageCount"
                              name="features.imageCount"
                              type="number"
                              className="form-control"
                              placeholder="Image Count"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values?.features?.imageCount || ''}
                              invalid={
                                validation.touched?.features?.imageCount && validation.errors?.features?.imageCount ? true : false
                              }
                          />
                          {validation.touched?.features?.imageCount && validation.errors?.features?.imageCount ? (
                              <FormFeedback
                                  type="invalid">{validation.errors?.features?.imageCount}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="form-check form-switch form-switch-lg mb-3">
                          <Input
                              key={validation.values?.features?.blurResult}
                              id="blurResult"
                              name="features.blurResult"
                              type="checkbox"
                              className="form-control form-check-input"
                              placeholder="Blur Result"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              defaultChecked={validation.values?.features?.blurResult || false}
                              invalid={
                                validation.touched?.features?.blurResult && validation.errors?.features?.blurResult ? true : false
                              }
                          />
                          <label
                              className="form-check-label"
                              htmlFor="customSwitchsizelg"
                          >
                            Blur Result
                          </label>
                        </div>
                        <div className="form-check form-switch form-switch-lg mb-3">
                          <Input
                              key={validation.values?.features?.notificationButtonShow}
                              id="notificationButtonShow"
                              name="features.notificationButtonShow"
                              type="checkbox"
                              className="form-control form-check-input"
                              placeholder="Is Show Notification Button"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              defaultChecked={validation.values?.features?.notificationButtonShow || false}
                              invalid={
                                validation.touched?.features?.notificationButtonShow && validation.errors?.features?.notificationButtonShow ? true : false
                              }
                          />
                          <label
                              className="form-check-label"
                              htmlFor="customSwitchsizelg"
                          >
                            Show Notification Button
                          </label>
                        </div>
                        <div className="form-check form-switch form-switch-lg mb-3">
                          <Input
                              type="checkbox"
                              className="form-control form-check-input"
                              placeholder="Is Car"
                              disabled
                              checked={isCar}
                          />
                          <label
                              className="form-check-label"
                              htmlFor="customSwitchsizelg"
                          >
                            Is Car
                          </label>
                        </div>
                        <div className="form-check form-switch form-switch-lg mb-3">
                          <Input
                              id="bundle"
                              name="bundle"
                              type="checkbox"
                              className="form-control form-check-input"
                              placeholder="Is Bundle"
                              checked={isBundle}
                              disabled
                          />
                          <label
                              className="form-check-label"
                              htmlFor="customSwitchsizelg"
                          >
                            Is Bundle
                          </label>
                        </div>
                        {!isBundle && <div className="mb-3">
                          <Label className="control-label">Categories</Label>
                          <Select
                              key={isCar}
                              classNamePrefix="select2-selection"
                              placeholder="Choose..."
                              title="Catefories"
                              name="features.categories"
                              isMulti
                              options={isCar ? carCategories : estateCategories}
                              value={(isCar ? carCategories : estateCategories)?.filter(item => validation.values?.features?.categories?.includes(item.value))}
                              onChange={(option, val) => {
                                validation.setFieldValue('features.categories', option?.map(i => i.value) || []);
                              }}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.features?.categories && validation.errors.features?.categories ? true : false
                              }
                          />
                          {validation.touched.features?.categories && validation.errors.features?.categories ? (
                              <FormFeedback type="invalid">{validation.errors.features?.categories}</FormFeedback>
                          ) : null}
                        </div>}
                      </Col>
                      <Col sm="12">
                        <div className="inner-repeater mb-4">
                          <Label>Description :</Label>
                          <table style={{ width: '100%' }}>
                            <tbody>
                            {(validation.values.desc || []).map((formRow, key) => (
                                <tr key={key}>
                                  <td>
                                    <Row className="mb-2">
                                      <Col md="10">
                                        <Input
                                            value={formRow}
                                            type="text"
                                            className="inner form-control"
                                            placeholder="Enter your phone no..."
                                            onChange={(e) => handleChangeRow(key, e.target.value)}
                                        />
                                      </Col>
                                      <Col md="2">
                                        <Button
                                            color="primary"
                                            className="btn-block inner"
                                            id="unknown-btn"
                                            style={{ width: '100%' }}
                                            onClick={e => handleRemoveRow(key)}
                                        >
                                          {' '}
                                          Delete{' '}
                                        </Button>
                                      </Col>
                                    </Row>
                                  </td>
                                </tr>
                            ))}
                            </tbody>
                          </table>
                          <Button
                              onClick={() => {
                                handleAddRowNested();
                              }}
                              color="success"
                              className="mt-1"
                          >
                            Add Point
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2 align-right">
                      <Button type="submit" color="primary" className="btn">
                        Save Changes
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Add
