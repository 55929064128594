import React from 'react';
import { Link } from 'react-router-dom';
import {Card, CardBody, Col, Row, UncontrolledTooltip} from 'reactstrap';

const List = ({ listData, onClickUpdate }) => (
  <React.Fragment>
      <Row>
          {(listData || []).map((item, key) => (
            <Col xl={4} key={key}>
                <Card>
                    <CardBody>
                        <div className="d-flex align-start mb-3">
                            <div className="flex-grow-1">
                                        <span
                                          className={!item.deactivated ? "badge badge-soft-success" : "badge badge-soft-warning"}>
                                            {item.deactivated ? "Deactivated" : "Active"}
                                        </span>
                            </div>
                            <div className="flex-grow-2">
                                        <span
                                          className={!item.disableNotification ? "badge badge-soft-success" : "badge badge-soft-warning"}>
                                            {item.disableNotification ? "Notification Disabled" : "Notification Enabled"}
                                        </span>
                            </div>
                        </div>
                        <div className="text-center mb-3">
                            {!item.picture ? (
                              <div className="avatar-sm d-inline-block">
                                        <span className="avatar-title rounded-circle">
                                          {item?.name?.charAt(0) || item?.email?.charAt(0).toUpperCase()}
                                        </span>
                              </div>
                            ) : (
                              <img src={item.picture} alt="" className="avatar-sm rounded-circle"/>
                            )}

                            <h6 className="font-size-15 mt-3 mb-1">{item.name}</h6>
                            <p className="mb-0 text-muted">{item.email}</p>
                        </div>
                        <div className="d-flex mb-3 justify-content-center gap-2 text-muted">
                            <div>
                                <i className='bx bx-map align-middle text-primary'></i> {item.country || '--'}
                            </div>
                            <p className="mb-0 text-center"><i
                              className='bx bx-phone align-middle text-primary'></i> {item.phone || '--'}</p>
                        </div>
                        <div className="d-flex gap-5 mt-2 pt-1 justify-content-center">
                            <button
                              type="button"
                              className={`btn btn-${item.deactivated ? 'success' : 'danger'}`}
                              onClick={() => {
                                  onClickUpdate({_id: item._id, deactivated: !item.deactivated});
                              }}
                            >
                                <i className="mdi mdi-lock-open-check font-size-16 align-middle me-2"></i>{" "}
                                {item.deactivated ? 'Activate' : 'Deactivate'}
                            </button>
                            <button
                              type="button"
                              className={`btn btn-${item.disableNotification ? 'success' : 'danger'}`}
                              onClick={() => {
                                  onClickUpdate({
                                      _id: item._id,
                                      disableNotification: !item.disableNotification
                                  });
                              }}
                            >
                                <i className="mdi mdi-access-point-network-off font-size-16 align-middle me-2"></i>{" "}
                                {item.disableNotification ? 'Enable' : 'Disable'}
                            </button>
                        </div>
                    </CardBody>
                </Card>
            </Col>
          ))}
      </Row>
  </React.Fragment>
);

export default List;