import {
  GET_TRANSLATIONS,
  GET_TRANSLATIONS_SUCCESS,
  GET_TRANSLATIONS_FAIL,
  ADD_TRANSLATION,
  ADD_TRANSLATION_SUCCESS,
  ADD_TRANSLATION_FAIL,
  DELETE_TRANSLATION,
  DELETE_TRANSLATION_SUCCESS,
  DELETE_TRANSLATION_FAIL,
  UPDATE_TRANSLATION,
  UPDATE_TRANSLATION_SUCCESS,
  UPDATE_TRANSLATION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  translations: [],
};

const Translations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRANSLATIONS:
      return {
        ...state,
        translations: action.payload,
        loading: true
      };

    case GET_TRANSLATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        translations: action.payload,
      };

    case ADD_TRANSLATION:
      return {
        ...state,
        loading: true,
      };

    case ADD_TRANSLATION_SUCCESS:
      return {
        ...state,
        loading: false,
        translations: [...state.translations, action.payload],
      };

    case ADD_TRANSLATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_TRANSLATION:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_TRANSLATION_SUCCESS:
      return {
        ...state,
        loading: false,
        translations: action.payload,
      };

    case UPDATE_TRANSLATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_TRANSLATION:
      return {
        ...state,
        loading: true,
      };

    case DELETE_TRANSLATION_SUCCESS:
      return {
        ...state,
        loading: false,
        translations: state.translations.filter(
          ad => ad._id !== action.payload
        ),
      };

    case DELETE_TRANSLATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Translations;
