/* PRODUCTS */
export const GET_CLIENTS = "GET_CLIENTS"
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS"
export const GET_CLIENTS_FAIL = "GET_CLIENTS_FAIL"

export const UPDATE_CLIENT = "UPDATE_CLIENT"
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS"
export const UPDATE_CLIENT_FAIL = "UPDATE_CLIENT_FAIL"

export const GET_CLIENT_PROFILE = "GET_CLIENT_PROFILE";
export const GET_CLIENT_PROFILE_SUCCESS = "GET_CLIENT_PROFILE_SUCCESS";
export const GET_CLIENT_PROFILE_FAIL = "GET_CLIENT_PROFILE_FAIL";
