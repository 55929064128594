import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";

//pass new generated access token here
function getAccessToken() {
  const user = JSON.parse(localStorage.getItem('authUser') || null);
  return user?.data?.token;
}
const user = JSON.parse(localStorage.getItem('authUser') || null);
const token = user?.data?.token;
//apply base url for axios
//const API_URL = "http://localhost:8081/api/v1";
const API_URL = "https://api.immagoo.com/api/v1";

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token || getAccessToken()}`;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = { 'Content-Type': 'application/json' }) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data)
    .catch(({ response }) => {
      if(response.status === 401) {
        localStorage.setItem('authUser', null);
        window.location.href = '/logout';
      }
    });
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
