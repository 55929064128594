import {
  GET_TRANSLATIONS,
  GET_TRANSLATIONS_SUCCESS,
  GET_TRANSLATIONS_FAIL,
  ADD_TRANSLATION,
  ADD_TRANSLATION_SUCCESS,
  ADD_TRANSLATION_FAIL,
  UPDATE_TRANSLATION,
  UPDATE_TRANSLATION_SUCCESS,
  UPDATE_TRANSLATION_FAIL,
  DELETE_TRANSLATION,
  DELETE_TRANSLATION_SUCCESS,
  DELETE_TRANSLATION_FAIL,
} from "./actionTypes"

export const getTranslation = () => ({
  type: GET_TRANSLATIONS,
})

export const getTranslationSuccess = ads => ({
  type: GET_TRANSLATIONS_SUCCESS,
  payload: ads,
})

export const getTranslationFail = error => ({
  type: GET_TRANSLATIONS_FAIL,
  payload: error,
})

export const addTranslation= ad => ({
  type: ADD_TRANSLATION,
  payload: ad,
})

export const addTranslationSuccess = ad => ({
  type: ADD_TRANSLATION_SUCCESS,
  payload: ad,
})

export const addTranslationFail = error => ({
  type: ADD_TRANSLATION_FAIL,
  payload: error,
})

export const updateTranslation = ad => ({
  type: UPDATE_TRANSLATION,
  payload: ad,
})

export const updateTranslationSuccess = ad => ({
  type: UPDATE_TRANSLATION_SUCCESS,
  payload: ad,
})

export const updateTranslationFail = error => ({
  type: UPDATE_TRANSLATION_FAIL,
  payload: error,
})

export const deleteTranslation = ad => ({
  type: DELETE_TRANSLATION,
  payload: ad,
})

export const deleteTranslationSuccess = ad => ({
  type: DELETE_TRANSLATION_SUCCESS,
  payload: ad,
})

export const deleteTranslationFail = error => ({
  type: DELETE_TRANSLATION_FAIL,
  payload: error,
})
