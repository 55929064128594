export const POST_JWT_LOGIN = `/user/sign-in-admin`;

//ADS
export const GET_ADS = "/backoffice/home-ads";
export const ADD_NEW_AD = "/backoffice/home-ads";
export const EDIT_AD = "/backoffice/home-ads";
export const DELETE_AD = "/backoffice/home-ads";

//CAR ADS
export const GET_CAR_ADS = "/backoffice/car-ads";
export const ADD_NEW_CAR_AD = "/backoffice/car-ads";
export const EDIT_CAR_AD = "/backoffice/car-ads";
export const DELETE_CAR_AD = "/backoffice/car-ads";

//PACKAGES
export const GET_PACKAGES = "/backoffice/packages";
export const ADD_PACKAGE = "/backoffice/packages";
export const DELETE_PACKAGE = "/backoffice/packages";
export const UPDATE_PACKAGE = "/backoffice/packages";

//TRANSLATIONS
export const GET_TRANSLATIONS = "/backoffice/translations";
export const ADD_TRANSLATIONS = "/backoffice/translations";
export const DELETE_TRANSLATION = "/backoffice/translations";
export const UPDATE_TRANSLATION = "/backoffice/translations";

//USERS
export const GET_USERS = "/backoffice/users";
export const GET_PROFILE = "/backoffice/users";
export const UPDATE_USER = "/backoffice/users";

//SAVED SEARCH
export const GET_SAVED_SEARCH = "/backoffice/saved-search";
export const DELETE_SAVED_SEARCH = "/backoffice/saved-search";