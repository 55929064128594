import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody, CardTitle,
    Progress
} from 'reactstrap';

import { get } from "../../../helpers/api_helper";

export const ByPlatform = props => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        get(`/reports/users-by-platform`)
            .then(({ data }) => {
                setLoading(false);
                setData(data)
            });
    }, []);

    const [first, ...sortedData] = data?.sort((a, b) => b.users - a.users);

    return loading ? <Progress animated color="primary" value={100} /> : (
        <Card>
            <CardBody>
                <CardTitle className="mb-4">Top Platforms Used</CardTitle>
                <div className="text-center">
                    <div className="mb-4">
                        <i className="bx bx-map-pin text-primary display-4" />
                    </div>
                    <h3>{first?.users} users</h3>
                    <h4>{first?.duration} minutes</h4>
                    <p>{first?.platform}</p>
                </div>

                <div className="table-responsive mt-4">
                    <table className="table align-middle table-nowrap">
                        <tbody>
                        {
                            (sortedData || []).map((item, index) => (
                                <tr key={index}>
                                    <td style={{ width: '30%' }}>
                                        <p className="mb-0">{item.platform}</p>
                                    </td>
                                    <td style={{ width: '25%' }}>
                                        <h5 className="mb-0">{item.users} users</h5>
                                    </td>
                                    <td style={{ width: '25%' }}>
                                        <h5 className="mb-0">{item.duration} minutes</h5>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
    );
};
