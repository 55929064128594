import {
  GET_ADS,
  GET_ADS_SUCCESS,
  GET_ADS_FAIL,
  ADD_AD_SUCCESS,
  ADD_AD_FAIL,
  DELETE_AD_SUCCESS,
  DELETE_AD_FAIL, EDIT_AD_SUCCESS, EDIT_AD_FAIL
} from './actionTypes';

const INIT_STATE = {
  ads: [],
};

const Ads = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADS:
      return {
        ...state,
        ads: action.payload,
        loading: true
      };

    case GET_ADS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_ADS_SUCCESS:
      return {
        ...state,
        ads: action.payload,
      };

    case ADD_AD_SUCCESS:
      return {
        ...state,
        ads: [...state.ads, action.payload],
      };

    case ADD_AD_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case EDIT_AD_SUCCESS:
      return {
        ...state,
        loading: false,
        packages: action.payload,
      };

    case EDIT_AD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_AD_SUCCESS:
      return {
        ...state,
        ads: state.ads.filter(
          ad => ad._id !== action.payload
        ),
      };

    case DELETE_AD_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Ads;
