import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_SAVED_SEARCH,
  DELETE_SAVED_SEARCH,
} from "./actionTypes"
import {
  getSavedSearchSuccess,
  getSavedSearchFail,
  deleteSavedSearchSuccess,
  deleteSavedSearchFail,
} from "./actions";

import {
  getSavedSearch,
  deleteSavedSearch,
} from "helpers/backend";

import 'react-toastify/dist/ReactToastify.css';

function* fetchSavedSearch() {
  try {
    const response = yield call(getSavedSearch);
    if(response.error) {
      yield put(getSavedSearchFail(response));
    } else {
      yield put(getSavedSearchSuccess(response.data));
    }
  } catch (error) {
    yield put(getSavedSearchFail(error));
  }
}

function* onDeleteSavedSearch({ payload: add }) {
  try {
    const response = yield call(deleteSavedSearch, add);
    yield put(deleteSavedSearchSuccess(add));
    toast.success("Saved Search Delete Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteSavedSearchFail(error));
    toast.error("Saved Search Delete Failed", { autoClose: 2000 });
  }
}

function* savedSearchSaga() {
  yield takeEvery(GET_SAVED_SEARCH, fetchSavedSearch);
  yield takeEvery(DELETE_SAVED_SEARCH, onDeleteSavedSearch);
}

export default savedSearchSaga;
