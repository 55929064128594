import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import makeAnimated from 'react-select/animated';

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
} from "reactstrap";

import TableContainer from '../../components/Common/TableContainer';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';

import {
    getTranslation,
    updateTranslation,
    addTranslation,
    deleteTranslation,
} from "store/actions";

import Spinners from "components/Common/Spinner";

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

const animatedComponents = makeAnimated();

const languages = [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'French'},
    { value: 'nl', label: 'Dutch' },
    { value: 'de', label: 'German' },
    { value: 'sp', label: 'Spanish' },
    { value: 'it', label: 'Italian' },
    { value: 'dk', label: 'Danish' },
    { value: 'pt', label: 'Portuguese' },
    { value: 'be', label: 'Belgian' },
    { value: 'ru', label: 'Russia' },
];

function JobList() {

    //meta title
    document.title = "Translations List | Immagoo";

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [jobsList, setJobsList] = useState([]);
    const [job, setJob] = useState(null);

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            key: job?.key || '',
            lang: job?.lang || 'en',
            value: job?.value || '',
        },
        validationSchema: Yup.object({
            key: Yup.string()
              .matches("^[A-Z0-9_]*$",'Must be only upper case, and can include only underscore.')
              .strict(true)
              .required("Please Enter Key"),
            lang: Yup.string().required("Please Enter Language"),
            value: Yup.string().required("Please Enter Translation"),
        }),
        onSubmit: (values) => {
            const handler = isEdit && job?._id ? updateTranslation : addTranslation;
            dispatch(handler({...values, _id: job?._id }));
            validation.resetForm();
            toggle();
        },
    });

    const dispatch = useDispatch();

    const selectJobsState = (state) => state.Translations;
    const JobsJobsProperties = createSelector(
        selectJobsState,
        (jobReducer) => ({
            translations: jobReducer.translations,
            loading: jobReducer.loading
        })
    );

    const {
        translations = [],
        loading
    } = useSelector(JobsJobsProperties);

    const [isLoading, setLoading] = useState(loading)

    useEffect(() => {
        dispatch(getTranslation());
    }, [dispatch]);

    const toggle = () => {
        if (modal) {
            setModal(false);
            setJob(null);
        } else {
            setModal(true);
        }
    };

    const handleJobClick = translation => {
        setJob({
            _id: translation._id,
            key: translation.key,
            lang: translation.lang,
            value: translation.value
        });
        setIsEdit(true);
        toggle();
    };

    //delete Job
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (job) => {
        setJob(job);
        setDeleteModal(true);
    };

    const handleDeletejob = () => {
        if (job && job._id) {
            dispatch(deleteTranslation(job._id));
            setDeleteModal(false);
        }
    };

    const handleJobClicks = () => {
        setJobsList("");
        setIsEdit(false);
        toggle();
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Key',
                accessor: 'key',
            },
            {
                Header: 'Language',
                accessor: 'lang',
                filterable: true,
                Cell: ({ cell }) => {
                    return cell.value ? languages.find(item => item.value === cell.value)?.label : "";
                }
            },
            {
                Header: 'Translation',
                accessor: 'value',
                filterable: true,
            },
            {
                Header: 'Created Date',
                accessor: 'createdAt',
                Cell: ({ cell }) => {
                    return cell.value ? cell.value.split('T')[0] :'';
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        handleJobClick(jobData);
                                    }}
                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        onClickDelete(jobData);
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-outline" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeletejob}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Translations" />
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            :
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">Translations Lists</h5>
                                                <div className="flex-shrink-0">
                                                    <Link to="#!"
                                                          onClick={() => setModal(true)}
                                                          className="btn btn-primary me-1"
                                                    >
                                                        Add Translation
                                                    </Link>
                                                </div>
                                            </div>
                                        </CardBody>
                                        <CardBody>
                                           <TableContainer
                                                columns={columns}
                                                data={translations}
                                                isGlobalFilter={true}
                                                isAddOptions={false}
                                                handleJobClicks={handleJobClicks}
                                                isJobListGlobalFilter={false}
                                                isPagination={true}
                                                isShowingPageLength={true}
                                                customPageSize={50}
                                                tableClass="table-bordered align-middle nowrap mt-2"
                                                paginationDiv="col-sm-12 col-md-7"
                                                pagination="pagination justify-content-end pagination-rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                    <Modal
                      isOpen={modal}
                      toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? "Edit Translation" : "Add Translation"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label className="control-label">Language</Label>
                                            <Select
                                              placeholder="Choose..."
                                              title="Language"
                                              name='lang'
                                              value={languages ? languages.find(option => option.value === validation.touched.lang) : ''}
                                              defaultValue={languages[0]}
                                              validate={{
                                                  required: { value: true },
                                              }}
                                              onChange={(option) => {
                                                  validation.setFieldValue('lang', option.value);
                                                  validation.setFieldTouched('lang', true);
                                              }}
                                              onBlur={validation.handleBlur}
                                              options={languages}
                                              components={animatedComponents}
                                              styles={{
                                                  menu: styles => ({ ...styles, backgroundColor: 'white' }),
                                              }}
                                              className="select2-selection"
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label"> Key</Label>
                                            <Input
                                                name="key"
                                                type="text"
                                                placeholder="Insert Key"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.key || ""}
                                                invalid={
                                                    validation.touched.key && validation.errors.key ? true : false
                                                }
                                            />
                                            {validation.touched.key && validation.errors.key ? (
                                                <FormFeedback type="invalid">{validation.errors.key}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Translation</Label>
                                            <Input
                                                name="value"
                                                type="text"
                                                placeholder="Insert Translation"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.value || ""}
                                                invalid={
                                                    validation.touched.value && validation.errors.value ? true : false
                                                }
                                            />
                                            {validation.touched.value && validation.errors.value ? (
                                                <FormFeedback type="invalid">{validation.errors.value}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}


export default JobList;