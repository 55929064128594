import {
  GET_SAVED_SEARCH,
  GET_SAVED_SEARCH_SUCCESS,
  GET_SAVED_SEARCH_FAIL,
  DELETE_SAVED_SEARCH,
  DELETE_SAVED_SEARCH_SUCCESS,
  DELETE_SAVED_SEARCH_FAIL,
} from "./actionTypes"

export const getSavedSearch = () => ({
  type: GET_SAVED_SEARCH,
})

export const getSavedSearchSuccess = ads => ({
  type: GET_SAVED_SEARCH_SUCCESS,
  payload: ads,
})

export const getSavedSearchFail = error => ({
  type: GET_SAVED_SEARCH_FAIL,
  payload: error,
})

export const deleteSavedSearch = ad => ({
  type: DELETE_SAVED_SEARCH,
  payload: ad,
})

export const deleteSavedSearchSuccess = ad => ({
  type: DELETE_SAVED_SEARCH_SUCCESS,
  payload: ad,
})

export const deleteSavedSearchFail = error => ({
  type: DELETE_SAVED_SEARCH_FAIL,
  payload: error,
})
