import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import { isEmpty } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import actions
import { getCarAds as onGetProducts, deleteCarAd } from "store/car-ads/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";

const EcommerceProducts = () => {

  //meta title
  document.title = "Car Adds | Immagoo";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectEcommerceState = (state) => state.CarAds;
  const EcommerceProductsProperties = createSelector(
    selectEcommerceState,
    (Ecommerce) => ({
      carAds: Ecommerce.carAds,
      loading: Ecommerce.loading
    })
  );

  const {
    carAds,
    loading,
  } = useSelector(EcommerceProductsProperties);

  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    dispatch(onGetProducts());
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbItem="Car Ads"
          >
            <Button color="primary" className="btn" onClick={() => navigate('/car-add-ad')}>
              New
            </Button>
          </Breadcrumbs>
          <Row>
            <Col lg={12}>
              {isLoading
                ? <Spinners setLoading={setLoading} />
                : <Row>
                    {!isEmpty(carAds) && (carAds || []).map((product, key) => (
                        <Col xl={4} sm={6} key={"_col_" + key}>
                          <Card >
                            <CardBody>
                              <div className="product-img position-relative">
                                <div
                                    className="avatar-sm product-ribbon"
                                    style={{ cursor: 'pointer', right: 70 }}
                                    onClick={() => navigate('/edit-car-ad/' + product._id)}
                                >
                                      <span className="avatar-title rounded-circle bg-secondary">
                                        <i className="bx bx-edit font-size-22"></i>
                                      </span>
                                </div>
                                <div
                                    className="avatar-sm product-ribbon"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => dispatch(deleteCarAd(product._id))}
                                >
                                      <span className="avatar-title rounded-circle bg-secondary">
                                        <i className="bx bx-trash font-size-22"></i>
                                      </span>
                                </div>

                                <img
                                    style={{ height: '330px' }}
                                    src={product?.photos?.[0]}
                                    className="img-fluid mx-auto d-block"
                                />
                              </div>
                              <div className="mt-4 text-center">
                                <h5 className="mb-3 text-truncate">
                                  {product.header}{' '}<br />
                                  {product.description}{' '}<br />
                                  {product.location}{' '}<br />
                                </h5>
                                <h5 className="my-0">
                                  <b>{product.price.main} {product.price.currency}</b>
                                </h5>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                  </Row>
              }
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

EcommerceProducts.propTypes = {
  ads: PropTypes.array,
  onGetProducts: PropTypes.func,
};

export default withRouter(EcommerceProducts);
