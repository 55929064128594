import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody, CardTitle, Col,
    Progress, Row
} from 'reactstrap';

import { get } from "../../../helpers/api_helper";

import ApexRadial from '../ApexRadial';

export const MonthlyIncome = props => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        get(`/reports/monthly-income`)
            .then(({ data }) => {
                setLoading(false);
                setData(data)
            });
    }, []);

    return loading ? <Progress animated color="primary" value={100} /> : (
        <Card>
            <CardBody>
                <CardTitle className="mb-4">Monthly Income</CardTitle>
                <Row>
                    <Col sm="6">
                        <h3>€ {data.total}</h3>
                        <p className="text-muted">
                            <span className="text-success me-2">
                                € {data.totalPaid} <i className="mdi mdi-arrow-up"></i>
                            </span>
                            Paid ({data.totalPaidCount})
                        </p>
                        <p className="text-muted">
                            <span className="text-warning me-2">
                                € {data.totalUnPaid} <i className="mdi mdi-arrow-down"></i>
                            </span>
                            UnPaid ({data.totalUnPaidCount})
                        </p>
                    </Col>
                    <Col sm="6">
                        <div className="mt-4 mt-sm-0">
                            <ApexRadial
                                dataColors='["--bs-primary"]'
                                series={[(data.totalPaid + data.totalUnPaid) * 100 / data.totalPaid]}
                                labels={['Paid']}
                            />
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};
