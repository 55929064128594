import PropTypes from "prop-types";
import React from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { withTranslation } from "react-i18next";

import { ByCountry } from './components/ByCountry';
import { ByDuration } from './components/ByDuration';
import { ByPlatform } from './components/ByPlatform';
import { MonthlyIncome } from './components/MonthlyIncome';
import { SummaryUsers } from './components/SummaryUsers';
import { LatestTransaction } from './components/LatestTranaction';
import { Usage } from './components/Usage';

const Dashboard = props => {
  //meta title
  document.title = "Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col xl="6">
              <ByPlatform/>
            </Col>
            <Col xl="6">
              <MonthlyIncome />
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <ByDuration />
            </Col>
          </Row>
          <Row>
            <SummaryUsers />
          </Row>
          <Row>
            <Col xl="12">
              <ByCountry/>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Usage />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <LatestTransaction />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
