import React, { useEffect, useState, useMemo } from "react";

import {
  Card,
  CardBody, Progress
} from 'reactstrap';

import { get } from "../../../helpers/api_helper";

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "./LatestTranactionCol";

import TableContainer from "../../../components/Common/TableContainer";
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export const LatestTransaction = props => {
  const [periodType, setPeriodType] = useState("yearly");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChangeChartPeriod = pType => {
    setPeriodType(pType);
  };

  useEffect(() => {
    setLoading(true);
    get(`/reports/transactions?date=${periodType}`)
        .then(({ data }) => {
          setLoading(false);
          setData(data)
        });
  }, [periodType]);

  const columns = useMemo(
    () => [
      {
        Header: "Order ID",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />;
        },
      },
      {
        Header: "Billing Name",
        accessor: "billing_details.name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Date",
        accessor: "created",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Total {...cellProps} />;
        },
      },
      {
        Header: "Payment Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <PaymentStatus {...cellProps} />;
        },
      },
      {
        Header: "Payment Method",
        accessor: "payment_method_details.type",
        disableFilters: true,
        Cell: cellProps => {
          return <PaymentMethod {...cellProps} />;
        },
      },
      {
        Header: "View Details",
        disableFilters: true,
        accessor: "receipt_url",
        Cell: cell => {
          return (
            <a
              href={cell.value}
              rel="noreferrer"
              target='_blank'
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
            >
              View Details
            </a>
          );
        },
      },
    ],
    []
  );

  return (
      <Card>
        <CardBody>
          <div className="d-sm-flex flex-wrap">
            <h4 className="card-title mb-4">Subscriptions</h4>
            <div className="ms-auto">
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <Link
                      to="#"
                      className={classNames(
                          { active: periodType === 'weekly' },
                          'nav-link'
                      )}
                      onClick={() => {
                        onChangeChartPeriod('weekly');
                      }}
                      id="one_month"
                  >
                    Week
                  </Link>{' '}
                </li>
                <li className="nav-item">
                  <Link
                      to="#"
                      className={classNames(
                          { active: periodType === 'monthly' },
                          'nav-link'
                      )}
                      onClick={() => {
                        onChangeChartPeriod('monthly');
                      }}
                      id="one_month"
                  >
                    Month
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                      to="#"
                      className={classNames(
                          { active: periodType === 'yearly' },
                          'nav-link'
                      )}
                      onClick={() => {
                        onChangeChartPeriod('yearly');
                      }}
                      id="one_month"
                  >
                    Year
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {loading ? <Progress animated color="primary" value={100} /> : <TableContainer
              columns={columns}
              data={data}
              isGlobalFilter={false}
              isAddOptions={false}
              isPagination={true}
              iscustomPageSizeOptions={false}
              customPageSize={20}
              pagination="pagination pagination-rounded justify-content-end mb-2"
          />}
        </CardBody>
      </Card>
  );
};
