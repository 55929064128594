import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_CAR_ADS,
  ADD_NEW_CAR_AD,
  DELETE_CAR_AD,
  EDIT_CAR_AD,
} from "./actionTypes"
import {
  getCarAdsSuccess,
  getCarAdsFail,
  addCarAdSuccess,
  addCarAdFail,
  deleteCarAdSuccess,
  deleteCarAdFail,
  editCarAdSuccess,
  editCarAdFail
} from "./actions";

import {
  getCarAds,
  deleteCarAd,
  addNewCarAd,
  editCarAd,
} from 'helpers/backend';

import 'react-toastify/dist/ReactToastify.css';

function* fetchCarAds() {
  try {
    const response = yield call(getCarAds);
    if(response.error) {
      yield put(getCarAdsFail(response));
    } else {
      yield put(getCarAdsSuccess(response.data));
    }
  } catch (error) {
    yield put(getCarAdsFail(error));
  }
}

function* onUpdateCarAd({ payload: add }) {
  try {
    const response = yield call(editCarAd, add);
    yield put(editCarAdSuccess(response?.data));
    toast.success("Car Ad Update Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(editCarAdFail(error));
    toast.error("Car Ad Update Failed", { autoClose: 2000 });
  }
}

function* onDeleteCarAdd({ payload: add }) {
  try {
    const response = yield call(deleteCarAd, add);
    yield put(deleteCarAdSuccess(add));
    toast.success("Ad Delete Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteCarAdFail(error));
    toast.error("Ad Delete Failed", { autoClose: 2000 });
  }
}

function* onAddNewCarAdd({ payload: add }) {
  try {
    const response = yield call(addNewCarAd, add);
    yield put(addCarAdSuccess(response));
    toast.success("Ad Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addCarAdFail(error));
    toast.error("Ad Added Failed", { autoClose: 2000 });
  }
}

function* carAdsSaga() {
  yield takeEvery(GET_CAR_ADS, fetchCarAds);
  yield takeEvery(ADD_NEW_CAR_AD, onAddNewCarAdd);
  yield takeEvery(EDIT_CAR_AD, onUpdateCarAd);
  yield takeEvery(DELETE_CAR_AD, onDeleteCarAdd);
}

export default carAdsSaga;
