import {
  GET_CAR_ADS,
  GET_CAR_ADS_SUCCESS,
  GET_CAR_ADS_FAIL,
  ADD_CAR_AD_SUCCESS,
  ADD_CAR_AD_FAIL,
  DELETE_CAR_AD_SUCCESS,
  DELETE_CAR_AD_FAIL,
  EDIT_CAR_AD_SUCCESS,
    EDIT_CAR_AD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  carAds: [],
};

const CarAds = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CAR_ADS:
      return {
        ...state,
        carAds: action.payload,
        loading: true
      };

    case GET_CAR_ADS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_CAR_ADS_SUCCESS:
      return {
        ...state,
        carAds: action.payload,
      };

    case ADD_CAR_AD_SUCCESS:
      return {
        ...state,
        carAds: [...state.carAds, action.payload],
      };

    case ADD_CAR_AD_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case EDIT_CAR_AD_SUCCESS:
      return {
        ...state,
        loading: false,
        packages: action.payload,
      };

    case EDIT_CAR_AD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_CAR_AD_SUCCESS:
      return {
        ...state,
        carAds: state.carAds.filter(
          ad => ad._id !== action.payload
        ),
      };

    case DELETE_CAR_AD_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default CarAds;
