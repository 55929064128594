import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import 'bootstrap/dist/css/bootstrap.min.css';
import TableContainer from 'components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

import {JsonView, allExpanded, darkStyles, defaultStyles, collapseAllNested} from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

//import components
import Breadcrumbs from 'components/Common/Breadcrumb';
import DeleteModal from 'components/Common/DeleteModal';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  getOrders as onGetOrders,
  getSavedSearch as onGetSavedSearch,
  deleteSavedSearch as onDeleteSavedSearch,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "store/actions";

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod
}
  from "./EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import moment from "moment";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";

function EcommerceOrder() {

  //meta title
  document.title = "Saved Searches | Immagoo";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [order, setOrder] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: (order && order.orderId) || '',
      billingName: (order && order.billingName) || '',
      orderdate: (order && order.orderdate) || '',
      total: (order && order.total) || '',
      paymentStatus: (order && order.paymentStatus) || 'Paid',
      badgeclass: (order && order.badgeclass) || 'success',
      paymentMethod: (order && order.paymentMethod) || 'Mastercard',
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(
          /[0-9\.\-\s+\/()]+/,
          "Please Enter Valid Order Id"
        ).required("Please Enter Your Order Id"),
      billingName: Yup.string().required("Please Enter Your Billing Name"),
      orderdate: Yup.string().required("Please Enter Your Order Date"),
      total: Yup.string().matches(
        /[0-9\.\-\s+\/()]+/,
        "Please Enter Valid Amount"
      ).required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          id: order ? order.id : 0,
          orderId: values.orderId,
          billingName: values.billingName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update order
        dispatch(onUpdateOrder(updateOrder));
        validation.resetForm();
      } else {
        const newOrder = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values["orderId"],
          billingName: values["billingName"],
          orderdate: values["orderdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        };
        // save new order
        dispatch(onAddNewOrder(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();

  const selectEcommerceState = (state) => state.SavedSearch;
  const EcommerceOrderProperties = createSelector(
    selectEcommerceState,
    (Ecommerce) => ({
      savedSearch: Ecommerce.savedSearch,
      loading: Ecommerce.loading
    })
  );

  const { savedSearch, loading, orders = [] } = useSelector(EcommerceOrderProperties);
  const [isLoading, setLoading] = useState(loading)

  const selectContactsState = (state) => state.Users;
  const ContactsProperties = createSelector(
      selectContactsState,
      (Contacts) => ({
        users: Contacts.clients,
        loading: Contacts.loading
      })
  );

  const {
    users = []
  } = useSelector(ContactsProperties);

  useEffect(() => {
      dispatch(onGetSavedSearch());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setIsEdit(false);
    }
  }, [orders]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order._id) {
      dispatch(onDeleteSavedSearch(order._id));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: '_id',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <OrderId {...cellProps} />;
        }
      },
      {
        Header: 'Country',
        accessor: 'country',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        }
      },
      {
        Header: 'Date',
        accessor: 'createdAt',
        filterable: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      {
        Header: 'User ID',
        accessor: 'userId',
        filterable: true,
        Cell: (cellProps) => {
          return (
              <Link target='_blank' to={`/users/${cellProps.value}`}>
                <BillingName
                    {...cellProps}
                    value={users?.find(({ _id }) => _id === cellProps.value)?.email || cellProps.value}
                />
              </Link>
          );
        }
      },
      {
        Header: 'Platform',
        accessor: 'isCar',
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value ? 'Cars' : 'Estate';
        }
      },
      {
        Header: 'Is Read',
        accessor: 'read',
        Cell: (cellProps) => {
          return <PaymentStatus {...cellProps} />;
        }
      },
      {
        Header: 'Filter',
        accessor: 'filter',
        Cell: (cellProps) => {
          return <JsonView data={cellProps.value} shouldExpandNode={() => false} style={defaultStyles} />
        }
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    [users]
  );

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="" breadcrumbItem="Saved Searches" />
          {
            isLoading ? <Spinners setLoading={setLoading} />
              :
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        key={users.length}
                        columns={columns}
                        data={savedSearch || []}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        handleOrderClicks={handleOrderClicks}
                        customPageSize={50}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          }
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;