/* PRODUCTS */
export const GET_ADS = "GET_ADS"
export const GET_ADS_SUCCESS = "GET_ADS_SUCCESS"
export const GET_ADS_FAIL = "GET_ADS_FAIL"

export const ADD_NEW_AD = "ADD_NEW_AD"
export const ADD_AD_SUCCESS = "ADD_AD_SUCCESS"
export const ADD_AD_FAIL = "ADD_AD_FAIL"

export const EDIT_AD = "EDIT_AD"
export const EDIT_AD_SUCCESS = "EDIT_AD_SUCCESS"
export const EDIT_AD_FAIL = "EDIT_AD_FAIL"

export const DELETE_AD = "DELETE_AD"
export const DELETE_AD_SUCCESS = "DELETE_AD_SUCCESS"
export const DELETE_AD_FAIL = "DELETE_AD_FAIL"
