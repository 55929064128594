import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_TRANSLATIONS,
  ADD_TRANSLATION,
  DELETE_TRANSLATION,
  UPDATE_TRANSLATION
} from "./actionTypes"
import {
  getTranslationSuccess,
  getTranslationFail,
  addTranslationSuccess,
  addTranslationFail,
  deleteTranslationSuccess,
  deleteTranslationFail,
  updateTranslationSuccess,
  updateTranslationFail,
} from "./actions";

import {
  getTranslations,
  addNewTranslation,
  updateTranslation,
  deleteTranslation,
} from "helpers/backend";

import 'react-toastify/dist/ReactToastify.css';

function* fetchTranslations() {
  try {
    const response = yield call(getTranslations);
    if(response.error) {
      yield put(getTranslationFail(response));
    } else {
      yield put(getTranslationSuccess(response.data));
    }
  } catch (error) {
    yield put(getTranslationFail(error));
  }
}

function* onDeleteTranslation({ payload: add }) {
  try {
    const response = yield call(deleteTranslation, add);
    yield put(deleteTranslationSuccess(add));
    toast.success("Ad Delete Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteTranslationFail(error));
    toast.error("Ad Delete Failed", { autoClose: 2000 });
  }
}

function* onAddNewTranslation({ payload: add }) {
  try {
    const response = yield call(addNewTranslation, add);
    yield put(addTranslationSuccess(response));
    toast.success("Ad Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addTranslationFail(error));
    toast.error("Ad Added Failed", { autoClose: 2000 });
  }
}

function* onUpdateTranslation({ payload: add }) {
  try {
    const response = yield call(updateTranslation, add);
    yield put(updateTranslationSuccess(response?.data));
    toast.success("Ad Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(updateTranslationFail(error));
    toast.error("Ad Added Failed", { autoClose: 2000 });
  }
}

function* translationsSaga() {
  yield takeEvery(GET_TRANSLATIONS, fetchTranslations);
  yield takeEvery(ADD_TRANSLATION, onAddNewTranslation);
  yield takeEvery(DELETE_TRANSLATION, onDeleteTranslation);
  yield takeEvery(UPDATE_TRANSLATION, onUpdateTranslation);
}

export default translationsSaga;
