import { del, get, post, put } from "./api_helper";
import * as url from "./url";
import { UPDATE_PACKAGE } from './url';

export const postJwtLogin = data => post(url.POST_JWT_LOGIN, data);

// ADS
export const getAds = () => get(url.GET_ADS);
export const addNewAd = ad => post(url.ADD_NEW_AD, ad);
export const editAd = ad => put(`${url.EDIT_AD}/${ad._id}`, ad);
export const deleteAd = ad => del(`${url.DELETE_AD}/${ad}`);

// CAR ADS
export const getCarAds = () => get(url.GET_CAR_ADS);
export const addNewCarAd = ad => post(url.ADD_NEW_CAR_AD, ad);
export const editCarAd = ad => put(`${url.EDIT_CAR_AD}/${ad._id}`, ad);
export const deleteCarAd = ad => del(`${url.DELETE_CAR_AD}/${ad}`);

// Packages
export const getPackages = () => get(url.GET_PACKAGES);
export const addNewPackage = pack => post(url.ADD_PACKAGE, pack);
export const updatePackage = pack => put(`${url.UPDATE_PACKAGE}/${pack._id}`, pack);
export const deletePackage = pack => del(`${url.DELETE_PACKAGE}/${pack}`);

// Translations
export const getTranslations = () => get(url.GET_TRANSLATIONS);
export const addNewTranslation = translation => post(url.ADD_TRANSLATIONS, translation);
export const updateTranslation = translation => put(`${url.UPDATE_TRANSLATION}/${translation._id}`, translation);
export const deleteTranslation = translation => del(`${url.DELETE_TRANSLATION}/${translation}`);

// Users
export const getUsers = () => get(url.GET_USERS);
export const getUserProfile = ({ payload }) => get(`${url.GET_PROFILE}/${payload}`);
export const updateUser = user => put(`${url.UPDATE_USER}/${user._id}`, user);

// Saved Search
export const getSavedSearch = () => get(url.GET_SAVED_SEARCH);
export const deleteSavedSearch = savedSearch => del(`${url.DELETE_SAVED_SEARCH}/${savedSearch}`);
