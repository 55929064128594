import {
  GET_ADS,
  GET_ADS_SUCCESS,
  GET_ADS_FAIL,
  ADD_NEW_AD,
  ADD_AD_SUCCESS,
  ADD_AD_FAIL,
  DELETE_AD,
  DELETE_AD_SUCCESS,
  DELETE_AD_FAIL,
  EDIT_AD_SUCCESS,
  EDIT_AD,
  EDIT_AD_FAIL
} from "./actionTypes"

export const getAds = () => ({
  type: GET_ADS,
})

export const getAdsSuccess = ads => ({
  type: GET_ADS_SUCCESS,
  payload: ads,
})

export const getAdsFail = error => ({
  type: GET_ADS_FAIL,
  payload: error,
})

export const addNewAd = ad => ({
  type: ADD_NEW_AD,
  payload: ad,
})

export const addAdSuccess = ad => ({
  type: ADD_AD_SUCCESS,
  payload: ad,
})

export const addAdFail = error => ({
  type: ADD_AD_FAIL,
  payload: error,
})

export const editAd = ad => ({
  type: EDIT_AD,
  payload: ad,
})

export const editAdSuccess = ad => ({
  type: EDIT_AD_SUCCESS,
  payload: ad,
})

export const editAdFail = error => ({
  type: EDIT_AD_FAIL,
  payload: error,
})

export const deleteAd = ad => ({
  type: DELETE_AD,
  payload: ad,
})

export const deleteAdSuccess = ad => ({
  type: DELETE_AD_SUCCESS,
  payload: ad,
})

export const deleteAdFail = error => ({
  type: DELETE_AD_FAIL,
  payload: error,
})
