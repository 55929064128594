import {
  GET_CAR_ADS,
  GET_CAR_ADS_SUCCESS,
  GET_CAR_ADS_FAIL,
  ADD_NEW_CAR_AD,
  ADD_CAR_AD_SUCCESS,
  ADD_CAR_AD_FAIL,
  EDIT_CAR_AD,
  EDIT_CAR_AD_SUCCESS,
  EDIT_CAR_AD_FAIL,
  DELETE_CAR_AD,
  DELETE_CAR_AD_SUCCESS,
  DELETE_CAR_AD_FAIL,
} from "./actionTypes"

export const getCarAds = () => ({
  type: GET_CAR_ADS,
})

export const getCarAdsSuccess = ads => ({
  type: GET_CAR_ADS_SUCCESS,
  payload: ads,
})

export const getCarAdsFail = error => ({
  type: GET_CAR_ADS_FAIL,
  payload: error,
})

export const addNewCarAd = ad => ({
  type: ADD_NEW_CAR_AD,
  payload: ad,
})

export const addCarAdSuccess = ad => ({
  type: ADD_CAR_AD_SUCCESS,
  payload: ad,
})

export const addCarAdFail = error => ({
  type: ADD_CAR_AD_FAIL,
  payload: error,
})

export const editCarAd = ad => ({
  type: EDIT_CAR_AD,
  payload: ad,
})

export const editCarAdSuccess = ad => ({
  type: EDIT_CAR_AD_SUCCESS,
  payload: ad,
})

export const editCarAdFail = error => ({
  type: EDIT_CAR_AD_FAIL,
  payload: error,
})

export const deleteCarAd = ad => ({
  type: DELETE_CAR_AD,
  payload: ad,
})

export const deleteCarAdSuccess = ad => ({
  type: DELETE_CAR_AD_SUCCESS,
  payload: ad,
})

export const deleteCarAdFail = error => ({
  type: DELETE_CAR_AD_FAIL,
  payload: error,
})
