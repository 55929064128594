import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_CLIENTS,
  UPDATE_CLIENT,
  GET_CLIENT_PROFILE,
} from "./actionTypes"
import {
  getClientsSuccess,
  getClientsFail,
  updateClientSuccess,
  updateClientFail,
  getClientProfileSuccess,
  getClientProfileFail,
} from './actions';

import {
  getUsers,
  updateUser,
  getUserProfile,
} from "helpers/backend";

import 'react-toastify/dist/ReactToastify.css';

function* fetchClients() {
  try {
    const response = yield call(getUsers);
    if(response.error) {
      yield put(getClientsFail(response));
    } else {
      yield put(getClientsSuccess(response.data));
    }
  } catch (error) {
    yield put(getClientsFail(error));
  }
}

function* fetchClientProfile(data, param) {
  try {
    const response = yield call(getUserProfile, data);
    if(response.error) {
      yield put(getClientProfileFail(response));
    } else {
      yield put(getClientProfileSuccess(response.data));
    }
  } catch (error) {
    yield put(getClientProfileFail(error));
  }
}

function* onUpdateClient({ payload: add }) {
  try {
    const response = yield call(updateUser, add);
    yield put(updateClientSuccess(response?.data));
    toast.success("Ad Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(updateClientFail(error));
    toast.error("Ad Added Failed", { autoClose: 2000 });
  }
}

function* usersSaga() {
  yield takeEvery(GET_CLIENTS, fetchClients);
  yield takeEvery(UPDATE_CLIENT, onUpdateClient);
  yield takeEvery(GET_CLIENT_PROFILE, fetchClientProfile);
}

export default usersSaga;
