import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row, Collapse, Label, Input } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import List from './List';

import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";

// selector
import Select from "react-select";
import { experienceData, jobListCandidate, jobType } from 'common/data';
import Spinners from 'components/Common/Spinner';
import { handleSearchData } from 'components/Common/searchFile';
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getClients as onGetUsers, updateClient as onUpdateUser} from "../../../store/users/actions";
import UpdateModal from "../../../components/Common/UpdateModal";

const CandidateList = () => {
    document.title = "Candidate List | Skote - React Admin & Dashboard Template";

    const dispatch = useDispatch();
    const [contact, setContact] = useState();

    const selectContactsState = (state) => state.Users;
    const ContactsProperties = createSelector(
      selectContactsState,
      (Contacts) => ({
          users: Contacts.clients,
          loading: Contacts.loading
      })
    );

    const {
        users, loading
    } = useSelector(ContactsProperties);

    const [isLoading, setLoading] = useState(loading);
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickUpdate = users => {
        setContact(users);
        setDeleteModal(true);
    };

    const handleDeleteUser = () => {
        if (contact && contact._id) {
            dispatch(onUpdateUser(contact));
        }

        setDeleteModal(false);
    };
    const [listData, setListData] = useState();

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const [selectDate, setSelectDate] = useState();
    const dateChange = (date) => {
        setSelectDate(date)
    };

    useEffect(() => setListData(users), [users])

    // search
    const hanldeSearchJob = (value) => {
        let search = value;
        handleSearchData({ setState: setListData, data: users, item: search })
    }

    const options = [
        { value: "Freelance", label: "Freelance" },
        { value: "Full Time", label: "Full Time" },
        { value: "Part Time", label: "Part Time" },
        { value: "Internship", label: "Internship" },
    ]

    useEffect(() => {
        dispatch(onGetUsers());
    }, []);

    console.log(users);
    return (
        <React.Fragment>
            <UpdateModal
              show={deleteModal}
              onDeleteClick={handleDeleteUser}
              onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="" breadcrumbItem="Clients List" />

                    <Row>
                        <Col lg={12}>
                            <Card className="job-filter">
                                <CardBody>
                                    <form action="#">
                                        <Row className="g-3">
                                            <Col xxl={4} lg={4}>
                                                <div className="position-relative">
                                                    <Input type="text" id="searchJob" autoComplete="off" placeholder="Search your candidate" onChange={(e) => hanldeSearchJob(e.target.value)} />
                                                </div>
                                            </Col>

                                            <Collapse isOpen={isOpen} id="collapseExample">
                                                <div>
                                                    <Row className="g-3">
                                                        <Col xxl={4} lg={6}>
                                                            <div>
                                                                <Label htmlFor="experience" className="form-label fw-semibold">Experience</Label>
                                                            </div>
                                                            {
                                                                (experienceData || []).map((item, index) => (
                                                                    <div className="form-check form-check-inline" key={index}>
                                                                        <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${item.id}`} value={item.value} />
                                                                        <Label className="form-check-label" htmlFor={`inlineCheckbox${item.id}`}>{item.label}</Label>
                                                                    </div>
                                                                ))
                                                            }
                                                        </Col>
                                                        <Col xxl={4} lg={6}>
                                                            <div>
                                                                <Label htmlFor="jobType" className="form-label fw-semibold">Job Type</Label>
                                                            </div>
                                                            {
                                                                (jobType || []).map((item, index) => (
                                                                    <div className="form-check form-check-inline" key={index}>
                                                                        <Input type="checkbox" id={`inlineCheckbox${item.id}`} value={item.value} />
                                                                        <Label className="form-check-label" htmlFor={`inlineCheckbox${item.id}`}>{item.label}</Label>
                                                                    </div>
                                                                ))
                                                            }
                                                        </Col>
                                                        <Col xxl={4} lg={4}>
                                                            <div className="position-relative">
                                                                <Label htmlFor="qualificationInput" className="form-label fw-semibold">Qualification</Label>
                                                                <Input type="text" className="form-control" id="qualificationInput" autoComplete="off" placeholder="Qualification" />
                                                                <i className="ri-government-line filter-icon"></i>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Collapse>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                    {isLoading
                      ? <Spinners setLoading={setLoading} />
                      : <List listData={listData} onClickUpdate={onClickUpdate}/>
                    }
                </Container>
            </div>
        </React.Fragment>
    );
}

export default CandidateList;