import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import {
  FormFeedback,
  CardTitle,
  Container,
  CardBody,
  Button,
  Input,
  Label,
  Card,
  Form,
  Col,
  Row,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import { addNewAd } from "../../store/home-ads/actions";

const countries = [
  { value: 'france', label: 'France'},
  { value: 'netherlands', label: 'Netherlands' },
  { value: 'germany', label: 'Germany' },
  { value: 'spain', label: 'Spain' },
  { value: 'italy', label: 'Italy' },
  { value: 'america', label: 'USA' },
  { value: 'denmark', label: 'Denmark' },
  { value: 'portugal', label: 'Portugal' },
  { value: 'great-britain', label: 'Great britain' },
  { value: 'belgium', label: 'Belgium' },
  { value: 'russia', label: 'Russia' },
]

const AddAd = () => {

  //meta title
  document.title = "Add Add | Immagoo";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      country: '',
      address: '',
      locality: '',
      postalCode: '',
      price: '',
      currency: '',
      sqft: '',
      beds: '',
      photo: '',
      photoSecond: '',
      photoFirth: '',
      photoFourth: '',
      website: '',
      url: '',
    },
    validationSchema: Yup.object({
      country: Yup.string().required("Please Select Country"),
      address: Yup.string().required("Please Enter Address"),
      locality: Yup.string().required("Please Enter Locality"),
      postalCode: Yup.string().required("Please Enter Postal Code"),
      price: Yup.number().required("Please Enter Price"),
      currency: Yup.string().required("Please Enter Currency"),
      sqft: Yup.number().required("Please Enter SQFT"),
      beds: Yup.number().required("Please Enter Beds"),
      photo: Yup.string().url().required("Please Enter Photo"),
      photoSecond: Yup.string().url(),
      photoFirth: Yup.string().url(),
      photoFourth: Yup.string().url(),
      website: Yup.string().required("Please Enter Website"),
      url: Yup.string().url().required("Please Enter Website"),
    }),
    onSubmit: (values) => {
      const {
        country,
        url,
        website,
        photo,
        photoSecond,
        photoFirth,
        photoFourth,
        price,
        currency,
        sqft,
        beds,
        address,
        locality,
        postalCode,
      } = values;
      dispatch(addNewAd(
        {
          country,
          sqft,
          beds,
          isCar: false,
          price: {
            main: price,
            currency,
          },
          location: {
            address,
            locality,
            postalCode,
          },
          link: url,
          website: [
            {
              value: website,
              link: url
            }
          ],
          photos: [
            photo,
            photoSecond,
            photoFirth,
            photoFourth
          ]
        }
      ));
      navigate('/ads');
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Estate Ads" breadcrumbItem="Add Homepage Ad" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Home Ad Information</CardTitle>
                  <p className="card-title-desc mb-4">
                    Fill all information below
                  </p>

                  <Form onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="control-label">Country</Label>
                          <Select
                            classNamePrefix="select2-selection"
                            placeholder="Choose..."
                            title="Country"
                            name='country'
                            options={countries}
                            value={countries ? countries.find(option => option.value === validation.touched.country) : ''}
                            onChange={(option) => validation.setFieldValue('country', option.value)}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.country && validation.errors.country ? true : false
                            }
                          />
                          {validation.touched.country && validation.errors.country ? (
                            <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="address">Address</Label>
                          <Input
                            id="address"
                            name="address"
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address || ""}
                            invalid={
                              validation.touched.address && validation.errors.address ? true : false
                            }
                          />
                          {validation.touched.address && validation.errors.address ? (
                            <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="locality">
                            Locality
                          </Label>
                          <Input
                            id="locality"
                            name="locality"
                            type="text"
                            className="form-control"
                            placeholder="Locality"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.locality || ""}
                            invalid={
                              validation.touched.locality && validation.errors.locality ? true : false
                            }
                          />
                          {validation.touched.locality && validation.errors.locality ? (
                            <FormFeedback type="invalid">{validation.errors.locality}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="postalCode">Postal Code</Label>
                          <Input
                            id="postalCode"
                            name="postalCode"
                            type="text"
                            className="form-control"
                            placeholder="Postal Code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.postalCode || ""}
                            invalid={
                              validation.touched.postalCode && validation.errors.postalCode ? true : false
                            }
                          />
                          {validation.touched.postalCode && validation.errors.postalCode ? (
                            <FormFeedback type="invalid">{validation.errors.postalCode}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="price">Price</Label>
                          <Input
                            id="price"
                            name="price"
                            type="number"
                            className="form-control"
                            placeholder="Price"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.price || ""}
                            invalid={
                              validation.touched.price && validation.errors.price ? true : false
                            }
                          />
                          {validation.touched.price && validation.errors.price ? (
                            <FormFeedback type="invalid">{validation.errors.price}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="currency">
                            Currency
                          </Label>
                          <Input
                            id="currency"
                            name="currency"
                            type="text"
                            className="form-control"
                            placeholder="Currency"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.currency || ""}
                            invalid={
                              validation.touched.currency && validation.errors.currency ? true : false
                            }
                          />
                          {validation.touched.currency && validation.errors.currency ? (
                            <FormFeedback type="invalid">{validation.errors.currency}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="sqft">SQFT</Label>
                          <Input
                            id="sqft"
                            name="sqft"
                            type="number"
                            className="form-control"
                            placeholder="sqft"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.sqft || ""}
                            invalid={
                              validation.touched.sqft && validation.errors.sqft ? true : false
                            }
                          />
                          {validation.touched.sqft && validation.errors.sqft ? (
                            <FormFeedback type="invalid">{validation.errors.sqft}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="beds">Beds</Label>
                          <Input
                            id="beds"
                            name="beds"
                            type="number"
                            className="form-control"
                            placeholder="Beds"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.beds || ""}
                            invalid={
                              validation.touched.beds && validation.errors.beds ? true : false
                            }
                          />
                          {validation.touched.beds && validation.errors.beds ? (
                            <FormFeedback type="invalid">{validation.errors.beds}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm="12">
                        <div className="mb-3">
                          <Label htmlFor="website">
                            Website
                          </Label>
                          <Input
                            id="website"
                            name="website"
                            type="text"
                            className="form-control"
                            placeholder="Website"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.website || ""}
                            invalid={
                              validation.touched.website && validation.errors.website ? true : false
                            }
                          />
                          {validation.touched.website && validation.errors.website ? (
                            <FormFeedback type="invalid">{validation.errors.website}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="url">
                            URL
                          </Label>
                          <Input
                            id="url"
                            name="url"
                            type="text"
                            className="form-control"
                            placeholder="Url"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.url || ""}
                            invalid={
                              validation.touched.url && validation.errors.url ? true : false
                            }
                          />
                          {validation.touched.url && validation.errors.url ? (
                            <FormFeedback type="invalid">{validation.errors.url}</FormFeedback>
                          ) : null}
                        </div>
                        {['photo', 'photoSecond', 'photoFirth', 'photoFourth'].map((photo, index) => (
                            <div className="mb-3" key={photo}>
                              <Label htmlFor="photo">
                                Photo {index+1}
                              </Label>
                              <Input
                                  id={photo}
                                  name={photo}
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values[photo] || ""}
                                  invalid={
                                    validation.touched[photo] && validation.errors[photo] ? true : false
                                  }
                              />
                              {validation.touched[photo] && validation.errors[photo] ? (
                                  <FormFeedback type="invalid">{validation.errors[photo]}</FormFeedback>
                              ) : null}
                            </div>
                        ))}
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Save Changes
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddAd
