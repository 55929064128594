/* PRODUCTS */
export const GET_TRANSLATIONS = "GET_TRANSLATIONS"
export const GET_TRANSLATIONS_SUCCESS = "GET_TRANSLATIONS_SUCCESS"
export const GET_TRANSLATIONS_FAIL = "GET_TRANSLATIONS_FAIL"

export const ADD_TRANSLATION = "ADD_NEW_TRANSLATION"
export const ADD_TRANSLATION_SUCCESS = "ADD_TRANSLATION_SUCCESS"
export const ADD_TRANSLATION_FAIL = "ADD_TRANSLATION_FAIL"

export const DELETE_TRANSLATION = "DELETE_TRANSLATION"
export const DELETE_TRANSLATION_SUCCESS = "DELETE_TRANSLATION_SUCCESS"
export const DELETE_TRANSLATION_FAIL = "DELETE_TRANSLATION_FAIL"

export const UPDATE_TRANSLATION = "UPDATE_TRANSLATION"
export const UPDATE_TRANSLATION_SUCCESS = "UPDATE_TRANSLATION_SUCCESS"
export const UPDATE_TRANSLATION_FAIL = "UPDATE_TRANSLATION_FAIL"
