import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Progress,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import classNames from "classnames";

import { get } from "../../../helpers/api_helper";
//redux
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import { Chart } from "react-google-charts";
const geoUrl =
    "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

import 'react-tooltip/dist/react-tooltip.css'

const data1 = [
    ["Country", "Popularity"],
    ["Germany", 200],
    ["United States", 300],
    ["Brazil", 400],
    ["Canada", 500],
    ["France", 600],
    ["RU", 700],
];
export const ByCountry = props => {
    const [periodType, setPeriodType] = useState("yearly");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState(false);

    const onChangeChartPeriod = pType => {
        setPeriodType(pType);
    };

    useEffect(() => {
        setLoading(true);
        get(`/reports/users-by-country?date=${periodType}`)
            .then(({ data }) => {
                setLoading(false);
                setData(data)
            });
    }, [periodType]);
    console.log(content);

    return (
        <Card>
            <CardBody>
                <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Users by Country</h4>
                    <div className="ms-auto">
                        <ul className="nav nav-pills">
                            <li className="nav-item">
                                <Link
                                    to="#"
                                    className={classNames(
                                        { active: periodType === 'daily' },
                                        'nav-link'
                                    )}
                                    onClick={() => {
                                        onChangeChartPeriod('daily');
                                    }}
                                    id="one_month"
                                >
                                    Daily
                                </Link>{' '}
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="#"
                                    className={classNames(
                                        { active: periodType === 'weekly' },
                                        'nav-link'
                                    )}
                                    onClick={() => {
                                        onChangeChartPeriod('weekly');
                                    }}
                                    id="one_month"
                                >
                                    Week
                                </Link>{' '}
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="#"
                                    className={classNames(
                                        { active: periodType === 'monthly' },
                                        'nav-link'
                                    )}
                                    onClick={() => {
                                        onChangeChartPeriod('monthly');
                                    }}
                                    id="one_month"
                                >
                                    Month
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="#"
                                    className={classNames(
                                        { active: periodType === 'yearly' },
                                        'nav-link'
                                    )}
                                    onClick={() => {
                                        onChangeChartPeriod('yearly');
                                    }}
                                    id="one_month"
                                >
                                    Year
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <ReactTooltip
                    id="my-tooltip"
                    place="bottom"
                    variant="info"
                    content={content}
                />
                {/* <div className="clearfix"></div> */}
                {loading ? <Progress animated color="primary" value={100} /> : (
                    <ComposableMap  data-tooltip-content="Hello world!">
                        <ZoomableGroup>
                        <Geographies geography={geoUrl}>
                            {({ geographies }) =>
                                geographies.map((geo) => {
                                    const item = data.find(item => item.country.toLowerCase() === geo.properties.name.toLowerCase());
                                    return (
                                        <Geography
                                            data-tooltip-id="my-tooltip"
                                            key={geo.rsmKey}
                                            geography={geo}
                                            onMouseEnter={() => {
                                                console.log(item);
                                                item && setContent(`${item?.value} new users in ${item?.country} `);
                                                !item && setContent(`0 new users in ${geo.properties.name} `);
                                            }}
                                            onMouseLeave={() => {
                                                setContent("");
                                            }}
                                            style={{
                                                default: {
                                                    fill: item ? "#2379ea" : "#D6D6DA",
                                                    outline: "none"
                                                },
                                                hover: {
                                                    fill: "#F53",
                                                    outline: "none"
                                                },
                                                pressed: {
                                                    fill: "#E42",
                                                    outline: "none"
                                                }
                                            }}
                                        />
                                    )
                                })
                            }
                        </Geographies>
                        </ZoomableGroup>
                    </ComposableMap>
                )}
            </CardBody>
        </Card>
    );
};
