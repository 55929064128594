import React, { useEffect, useState } from 'react';
import {
  Col,
  Card,
  Nav,
  CardBody,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table, Progress
} from 'reactstrap';
import classnames from "classnames"

//Simple bar
import SimpleBar from "simplebar-react"
import { transactionsDataALL, transactionsDataBuy, transactionsDataSell } from "common/data"
import { get } from '../../../helpers/api_helper';

const FILTER_ENUM = {
  saved_search: 'Saved Search',
  favorite: 'Favorite',
  push: 'Push notification',
  filter: 'Filter',
  filter_radius: 'Radius Filter',
  filter_price: 'Price Filter',
  filter_sqft: 'SQFT Filter',
  filter_room: 'Room Filter',
}

export const Usage = () => {
  const [activeTab, setactiveTab] = useState('saved_search');

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    get(`/reports/db-reports`)
        .then(({ data }) => {
          setLoading(false);
          setData(data)
        });
  }, []);

  return loading ? <Progress animated color="primary" value={100} /> : (
    <Col xl="12">
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">Filters Usage</h4>
          <Nav pills className="bg-light rounded" role="tablist">
            {Object.entries(FILTER_ENUM).map(([key, value]) => (
              <NavItem key={key}>
                <NavLink
                    className={classnames({ active: activeTab === key })}
                    onClick={() => {
                      setactiveTab(key)
                    }}
                >
                  {value}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab} className="mt-4">
            {Object.keys(data).map(key => console.log(key) || (
              <TabPane tabId={key} key={key}>
                <SimpleBar style={{ maxHeight: "330px" }}>
                  <div className="table-responsive">
                    <Table className="table align-middle table-nowrap">
                      <tbody>
                      <tr>
                        <td>
                          Country
                        </td>
                        <td>
                          User
                        </td>
                        <td>
                          Updated At
                        </td>
                        <td>
                          Count
                        </td>
                        <td>
                          Platform
                        </td>
                      </tr>
                      {
                        (data[key] || []).map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div>
                                  <h5 className="font-size-14 mb-0">{item.country}</h5>
                                </div>
                              </td>

                              <td>
                                <div>
                                  <h5 className="font-size-14 mb-1">{item.user ? item.user.email : item.userId}</h5>
                                  <p className="text-muted mb-0">{item.updatedAt}</p>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <h5 className="font-size-14 mb-1">{item.updatedAt.split('T')[0]}</h5>
                                </div>
                              </td>

                              <td>
                                <div className={`font-size-22 text-danger`}>
                                  {item.value}
                                </div>
                              </td>

                              <td>
                                <div>
                                  <h5 className="font-size-14 text-muted mb-0">
                                    {item.platform}
                                  </h5>
                                </div>
                              </td>
                            </tr>
                        ))
                      }

                      </tbody>
                    </Table>
                  </div>
                </SimpleBar>
              </TabPane>
            ))}
          </TabContent>
        </CardBody>
      </Card>
    </Col>
  )
}
